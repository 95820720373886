// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KkUko {\n  display: flex;\n  flex-direction: column;\n  width: 255px;\n}\n\n  .VNAf6 {\n    font-weight: 600;\n    margin-bottom: var(--m);\n  }\n\n  ._8ySbH {\n    margin: 16px 12px;\n  }\n\n  .KkUko .bp3-divider {\n    margin: 0;\n  }\n\n  .stwqp {\n    padding: 0 !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SortMenu": "KkUko",
	"SortMenu_sortMenuTitle": "VNAf6",
	"SortMenu_sortMenuContainer": "_8ySbH",
	"SortMenu_colorByTerritoryToastButton": "stwqp"
};
export default ___CSS_LOADER_EXPORT___;
