// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ho972 {\n  position: absolute;\n  width: 100%;\n  box-shadow: var(--elevation-2);\n  pointer-events: all;\n  display: flex;\n  flex-direction: column;\n  background-color: white;\n  transition-property: height, max-height;\n  transition-duration: 0.2s;\n  transition-timing-function: ease-in-out;\n  border-radius: var(--s);\n  padding: 0 var(--s);\n  overflow: hidden;\n}\n\n  .RH6Oe {\n    box-shadow: none;\n  }\n\n  ._0Gm1T {\n    padding: 0 var(--s);\n    overflow: unset;\n  }\n\n  body:active .ho972 {\n    transition: unset;\n  }\n\n  ._9JB3V {\n    top: 0;\n  }\n\n  .I4fqG {\n    bottom: 0;\n  }\n\n  .KXxSb {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: var(--xs) 0;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n  }\n\n  .T0ztx {\n    max-height: 40px;\n    width: 100%;\n  }\n\n  .CtUpL {\n      margin-bottom: var(--s);\n    }\n\n  ._9JB3V ._5bXGJ {\n    margin-top: auto;\n  }\n\n  .FuamB {\n    margin: 7px 0 8px;\n    border-top: 1px solid #dde0ee;\n  }\n\n  ._9QE7T {\n    flex-grow: 2;\n    overflow: hidden;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GutterPanel": "ho972",
	"GutterPanel__isFlat": "RH6Oe",
	"GutterPanel__isExpanded": "_0Gm1T",
	"GutterPanel__isTop": "_9JB3V",
	"GutterPanel__isBottom": "I4fqG",
	"GutterPanel_header": "KXxSb",
	"GutterPanel_headerContent": "T0ztx",
	"GutterPanel_headerContent__minimal": "CtUpL",
	"GutterPanel_handleContainer": "_5bXGJ",
	"GutterPanel_panelDivider": "FuamB",
	"GutterPanel_panelContent": "_9QE7T"
};
export default ___CSS_LOADER_EXPORT___;
