import React from 'react';

import { Field, useFormikContext } from 'formik';

import FormDatePicker from 'app/components/FormFields/FormDatePicker/FormDatePicker';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';

import {
  CommandCenterHierarchyPanelContentFormValues,
  HierarchyAttributeType,
  HierarchyType,
  StaticHierarchyFieldProps
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './CommandCenterHierarchyPanelContentAttributeFields.module.pcss';

const b = block(style);

interface CommandCenterHierarchyPanelContentAttributeFieldsProps {
  hierarchyType: HierarchyType;
  showGeoKeyFields: boolean;
}

const baseCustomerFields = (values: CommandCenterHierarchyPanelContentFormValues): StaticHierarchyFieldProps[] => [
  { name: formatMessage('COUNTRY'), val: values.country, type: HierarchyAttributeType.TEXT, fieldName: 'country' },
  { name: formatMessage('CITY'), val: values.city, type: HierarchyAttributeType.TEXT, fieldName: 'city' },
  {
    name: formatMessage('STATE_OR_PROVINCE'),
    val: values.stateProvince,
    type: HierarchyAttributeType.TEXT,
    fieldName: 'stateProvince'
  },
  {
    name: formatMessage('ZIP_OR_POSTAL'),
    val: values.zipPostal,
    type: HierarchyAttributeType.TEXT,
    fieldName: 'zipPostal'
  },
  { name: formatMessage('ADDRESS1'), val: values.address1, type: HierarchyAttributeType.TEXT, fieldName: 'address1' },
  { name: formatMessage('ADDRESS2'), val: values.address2, type: HierarchyAttributeType.TEXT, fieldName: 'address2' },
  { name: formatMessage('INDUSTRY'), val: values.industry, type: HierarchyAttributeType.TEXT, fieldName: 'industry' }
];

const geoKeyFields = (values: CommandCenterHierarchyPanelContentFormValues): StaticHierarchyFieldProps[] => [
  {
    name: formatMessage('LINKED_GEO_KEY'),
    val: values.linkedGeoKey,
    type: HierarchyAttributeType.TEXT,
    fieldName: 'linkedGeoKey',
    disabled: true
  },
  {
    name: formatMessage('VISUAL_GEO_KEY'),
    val: values.visualGeoKey,
    type: HierarchyAttributeType.TEXT,
    fieldName: 'visualGeoKey',
    disabled: true
  }
];

const createStaticFieldProps = (
  hierarchyType: HierarchyType,
  showGeoKeyFields: boolean,
  values: CommandCenterHierarchyPanelContentFormValues
): StaticHierarchyFieldProps[] => {
  if (hierarchyType !== HierarchyType.CustomerAccountHierarchy) return [];
  if (showGeoKeyFields) return [...baseCustomerFields(values), ...geoKeyFields(values)];
  return baseCustomerFields(values);
};

const CommandCenterHierarchyPanelContentAttributeFields: React.FC<
  CommandCenterHierarchyPanelContentAttributeFieldsProps
> = ({ hierarchyType, showGeoKeyFields }: CommandCenterHierarchyPanelContentAttributeFieldsProps) => {
  const { values, errors, touched } = useFormikContext<CommandCenterHierarchyPanelContentFormValues>();

  const staticAttributeFields = createStaticFieldProps(hierarchyType, showGeoKeyFields, values);
  const attributeFields = [...staticAttributeFields, ...values.customProperties];

  return (
    <div className={b()} data-testid="command-center-hierarchy-panel-content-attribute-fields">
      {attributeFields.map((attribute, index) => {
        const customAttributeIndex = index - staticAttributeFields.length;
        const hasError =
          touched.customProperties?.[customAttributeIndex] && errors.customProperties?.[customAttributeIndex];

        return (
          <div key={attribute.name}>
            <Field
              label={
                <div className={b('formFieldLabel')} data-testid={`${attribute.name}-label`}>
                  {attribute.name}
                </div>
              }
              disabled={attribute.disabled}
              name={'fieldName' in attribute ? attribute.fieldName : `customProperties[${customAttributeIndex}].val`}
              theme="default"
              component={attribute.type === HierarchyAttributeType.DATE ? FormDatePicker : FormTextInputGroup}
              customError={hasError ? errors.customProperties[customAttributeIndex]['val'] : null}
              dateFormat="yyyy-MM-dd"
            />
          </div>
        );
      })}
    </div>
  );
};

export default CommandCenterHierarchyPanelContentAttributeFields;
