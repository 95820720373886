import React from 'react';

import { PopoverInteractionKind } from '@blueprintjs/core';
import { IPopover2SharedProps, Placement, Popover2 } from '@blueprintjs/popover2';

import block from 'utils/bem-css-modules';

import style from './Popover.module.pcss';

const b = block(style);
export interface PopoverProps {
  content: string | JSX.Element;
  portalClassName?: string;
  popoverClassName?: string;
  className?: string;
  placement?: Placement;
  children: unknown;
  modifiers?: IPopover2SharedProps<void>['modifiers'];
  interactionKind?: PopoverInteractionKind;
  openOnTargetFocus?: boolean;
  usePortal?: boolean;
  popoverRef?: (ref: HTMLElement) => void;
  disabled?: boolean;
  onOpened?: () => void;
  onClosed?: () => void;
  minimal?: boolean;
  isOpen?: boolean;
  onInteraction?: (nextOpenState: boolean) => void;
}

const Popover: React.FC<PopoverProps> = ({
  content,
  placement = 'auto',
  children,
  portalClassName,
  popoverClassName,
  className,
  interactionKind = PopoverInteractionKind.CLICK,
  modifiers = null,
  openOnTargetFocus = true,
  usePortal = true,
  popoverRef = null,
  disabled,
  onOpened,
  onClosed,
  minimal = false,
  isOpen = null,
  onInteraction = null
}) => {
  return (
    <Popover2
      onInteraction={onInteraction}
      isOpen={isOpen}
      content={content}
      interactionKind={interactionKind}
      portalClassName={portalClassName}
      popoverClassName={popoverClassName ? `${b()} ${popoverClassName}` : b()}
      className={className}
      placement={placement}
      modifiers={modifiers}
      autoFocus={false}
      openOnTargetFocus={openOnTargetFocus}
      usePortal={usePortal}
      popoverRef={popoverRef}
      disabled={disabled}
      onOpened={onOpened}
      onClosed={onClosed}
      minimal={minimal}
    >
      {children}
    </Popover2>
  );
};

export default Popover;
