import React from 'react';

import { AddAlt, TrashCan } from '@carbon/icons-react';
import { Intent, Text } from '@varicent/components';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Dialog from 'components/Dialog/Dialog';

import FormCheckbox from 'app/components/FormFields/FormCheckbox/FormCheckbox';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import { CustomHierarchyFilterMenuV2 } from 'app/components/TerritoryMap/CustomHierarchyFilterMenuV2';

import { CollectionFilter, NamedHierarchy } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SegmentEditorDialog.module.pcss';

const b = block(style);

interface SegmentEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  hierarchies: NamedHierarchy[];
  filter: CollectionFilter<number>;
}

interface SegmentEditorFormValues {
  segmentName: string;
  filter: CollectionFilter<number>;
  applySegment: boolean;
}

const SegmentEditorDialog: React.FC<SegmentEditorDialogProps> = ({ isOpen, onClose, hierarchies, filter }) => {
  const initialFormValues: SegmentEditorFormValues = { segmentName: '', filter: null, applySegment: false };

  const validationSchema = yup.object().shape({
    segmentName: yup.string().required(formatMessage('REQUIRED_FIELD'))
  });

  return (
    <Dialog size="small" title={formatMessage('CREATE_SEGMENT')} isOpen={isOpen} showDialogFooter={false}>
      <div className={b()}>
        <Text>{formatMessage('CREATE_SEGMENT_DIALOG_DESCRIPTION')}</Text>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={() => {
            return;
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <>
              <Field
                className={b('segmentNameField')}
                name="segmentName"
                component={FormTextInputGroup}
                label={formatMessage('SEGMENT_NAME')}
              />
              <div className={b('customHierarchyFilterMenuContainer')}>
                <Field
                  name="filter"
                  component={CustomHierarchyFilterMenuV2}
                  showFooter={false}
                  hierarchies={hierarchies}
                  filter={filter}
                  onUpdateFilter={(filter) => setFieldValue('filter', filter)}
                />
              </div>
              <div className={b('applyCheckboxField')}>
                <Field
                  name="applySegment"
                  component={FormCheckbox}
                  labelElement={<span className={b('applySegmentLabel')}>{formatMessage('APPLY_SEGMENT')}</span>}
                  type="checkbox"
                />
              </div>
              <div className={b('footer')}>
                <div>
                  <TextButton
                    minimal
                    intent={Intent.DANGER}
                    type="button"
                    testId="delete-button"
                    text={formatMessage('DELETE')}
                    icon={<TrashCan />}
                  />
                </div>
                <div>
                  <TextButton
                    className={b('rightActionButtons')}
                    type="button"
                    testId="cancel-button"
                    text={formatMessage('CANCEL')}
                    onClick={onClose}
                    minimal
                  />
                  <TextButton
                    className={b('rightActionButtons')}
                    intent={Intent.PRIMARY}
                    type="button"
                    testId="create-button"
                    text={formatMessage('CREATE')}
                    icon={<AddAlt />}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default SegmentEditorDialog;
