// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".An6G1 {\n    max-height: 100%;\n    overflow: hidden;\n  }\n  .yBy1Q {\n    margin-top: var(--xs);\n    display: flex;\n    flex-direction: column;\n    border: 1px solid rgb(var(--color-light-gray-2));\n    border-radius: var(--xs);\n    max-height: 100%;\n    overflow-y: auto;\n  }\n  .Yd-iz {\n    margin: 0;\n    border-top: 1px solid rgb(var(--color-light-gray-2));\n  }\n  .b4mgc {\n    box-shadow: none;\n  }\n  .b4mgc .vds-icon {\n    /* Used to match the icon location of the kind-select */\n    margin-right: 0 !important;\n  }\n  .\\-7YRW {\n    margin-top: var(--xs);\n  }\n  ._3zDNf {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n  .Trvsi {\n    display: flex;\n    justify-content: center;\n    margin-top: var(--m);\n  }\n  .EWiyS {\n    display: flex;\n    margin-top: var(--m);\n  }\n  .btmnl {\n    margin-left: var(--m);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CustomHierarchyFilterMenuV2_filter": "An6G1",
	"CustomHierarchyFilterMenuV2_form": "yBy1Q",
	"CustomHierarchyFilterMenuV2_fieldSeparator": "Yd-iz",
	"CustomHierarchyFilterMenuV2_hierarchyMultiSelect": "b4mgc",
	"CustomHierarchyFilterMenuV2_filterCallout": "-7YRW",
	"CustomHierarchyFilterMenuV2_filterFooter": "_3zDNf",
	"CustomHierarchyFilterMenuV2_saveSegment": "Trvsi",
	"CustomHierarchyFilterMenuV2_filterApplyAndClear": "EWiyS",
	"CustomHierarchyFilterMenuV2_applyFilterButton": "btmnl"
};
export default ___CSS_LOADER_EXPORT___;
