import React from 'react';

import { Intent } from '@blueprintjs/core';

import Dialog from 'components/Dialog/Dialog';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { TargetRuleComponent } from 'app/graphql/generated/graphqlApolloTypes';
import { useUnassignRuleHierarchies } from 'app/graphql/mutations/unassignRuleHierarchies';

import useShowToast from 'app/hooks/useShowToast';

import { FixConflictDetails } from 'app/models';

import { formatListSummary, formatMessage } from 'utils/messages/utils';

interface FixConflictDialogProps {
  onTerritoryUpdated: () => void;
  onClose: () => void;
  fixConflictDetails: FixConflictDetails;
}

const NAME_PREVIEW_COUNT = 6;

const FixConflictDialog: React.FC<FixConflictDialogProps> = ({
  onClose,
  onTerritoryUpdated,
  fixConflictDetails
}: FixConflictDialogProps) => {
  const { selectedTerritoryGroupId } = useMapContextRedistributor();
  const [unassignRuleHierarchies, { loading: loadingUnassignRuleHierarchies }] = useUnassignRuleHierarchies();
  const showToast = useShowToast();

  const { hierarchyIdsBeingRemoved, territoryId, ruleId, hierarchyNamesBeingRemoved } = fixConflictDetails;

  const successToastMessage = formatMessage(
    hierarchyIdsBeingRemoved.length > 1 ? 'REMOVE_CONFLICTS' : 'REMOVE_CONFLICT',
    {
      count: hierarchyIdsBeingRemoved.length,
      territoryId
    }
  );

  const unassignRuleHierarchiesToFixConflict = async () => {
    unassignRuleHierarchies({
      variables: {
        input: {
          scopingTerritoryGroupId: selectedTerritoryGroupId,
          sourceHierarchyIds: hierarchyIdsBeingRemoved,
          ruleIdsFilter: [ruleId],
          targetRuleComponent: TargetRuleComponent.Dimension
        }
      },
      onCompleted: () => {
        showToast(successToastMessage, 'success');
        onTerritoryUpdated();
        onClose();
      },
      onError: (error) => {
        showToast(formatMessage('UPDATE_TERRITORY_ERROR'), 'danger');
        throw error;
      }
    });
  };

  return (
    <Dialog
      isOpen
      onClose={onClose}
      onSubmit={unassignRuleHierarchiesToFixConflict}
      confirmButtonText={formatMessage('REMOVE_CONFLICT_BUTTON')}
      confirmButtonIntent={Intent.DANGER}
      title={formatMessage('REMOVE_CONFLICT_DIALOG_TITLE')}
      bodyMinHeight={0}
      confirmButtonLoading={loadingUnassignRuleHierarchies}
      size="small"
    >
      <div data-testid="fix-conflict-dialog">
        {formatMessage('REMOVE_CONFLICT_DIALOG_TEXT', { territory: territoryId })}
        <div>{formatListSummary(hierarchyNamesBeingRemoved, NAME_PREVIEW_COUNT)}</div>
      </div>
    </Dialog>
  );
};

export default FixConflictDialog;
