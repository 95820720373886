import React, { useState } from 'react';

import { ChevronDown, AddAlt } from '@carbon/icons-react';
import { Button, DividerV2, Intent } from '@varicent/components';

import { Menu } from 'components/menu/Menu';
import { MenuItem } from 'components/menu/MenuItem';
import Popover from 'components/Popover/Popover';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SegmentPicker.module.pcss';

const b = block(style);

const SAMPLE_SEGMENTS = ['Hardware technology', 'Software technology', 'Legacy technology'];

interface SegmentPickerProps {
  selectedSegment: string;
  setSelectedSegment: (segment: string) => void;
}

const SegmentPicker: React.FC<SegmentPickerProps> = ({ selectedSegment, setSelectedSegment }) => {
  const [isSegmentPickerOpen, setIsSegmentPickerOpen] = useState(false);

  return (
    <div className={b()}>
      <Popover
        isOpen={isSegmentPickerOpen}
        usePortal={false}
        className={b('segmentSelectionDropdown')}
        content={
          <Menu className={b('segmentSelectionMenu')} data-testid="segment-selection-menu">
            <MenuItem
              onClick={() => {
                setIsSegmentPickerOpen(false);
                setSelectedSegment('Hierarchies');
              }}
              text={formatMessage('HIERARCHIES')}
              data-testid="hierarchies-option"
            />
            <DividerV2 className={b('divider')} />
            <MenuItem
              text={formatMessage('SEGMENTS')}
              data-testid="segment-menu-label"
              className={b('segmentMenuItemLabel')}
            />
            {SAMPLE_SEGMENTS.map((segment) => (
              <MenuItem
                onClick={() => {
                  setIsSegmentPickerOpen(false);
                  setSelectedSegment(segment);
                }}
                key={segment}
                text={segment}
                data-testid={segment}
              />
            ))}
            <DividerV2 className={b('divider')} />
            <div className={b('createSegmentButtonWrapper')}>
              <Button className={b('createSegmentButton')} text={formatMessage('CREATE_SEGMENT')} icon={AddAlt} />
            </div>
          </Menu>
        }
        minimal
        placement="bottom-start"
      >
        <Button
          rightIcon={ChevronDown}
          text={selectedSegment}
          type="button"
          onClick={() => setIsSegmentPickerOpen(!isSegmentPickerOpen)}
          intent={Intent.NONE}
          data-testid="segment-dropdown-button"
          className={b('segmentDropdownButton')}
        />
      </Popover>
    </div>
  );
};

export default SegmentPicker;
