import React, { useEffect, useState, useMemo } from 'react';

import {
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  SelectionChangedEvent
} from '@ag-grid-community/core';
import {
  QueryResult,
  // eslint-disable-next-line no-restricted-imports
  useLazyQuery,
  // eslint-disable-next-line no-restricted-imports
  useMutation
} from '@apollo/client';
import { Intent } from '@blueprintjs/core';
import { TrashCan } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import { getPaginationCheck } from 'app/components/AdvancedGrid/GridHelper';
import CreateUserDialog from 'app/components/UserManagementPanel/CreateUserDialog/CreateUserDialog';

import { useScope } from 'app/contexts/scopeProvider';

import FileUploadDialog from 'app/core/fileUpload/FileUploadDialog/FileUploadDialog';
import { useFileUpload } from 'app/core/fileUpload/fileUploadProvider';
import { UserProfile, useUser } from 'app/core/userManagement/userProvider';

import { DEFAULT_BLOCK_SIZE } from 'app/global/variables';

import {
  GetMemberCount,
  GetMemberCountVariables,
  GetMemberList,
  GetMemberListVariables,
  SortDirection,
  SortModelItem
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { DELETE_MEMBER } from 'app/graphql/mutations/deleteMember';
import { INVITE_USER } from 'app/graphql/mutations/inviteUser';
import { REVOKE_SYSTEM_ROLE } from 'app/graphql/mutations/revokeSystemRole';
import { GET_MEMBER_LIST } from 'app/graphql/queries/getMemberList';

import useShowToast from 'app/hooks/useShowToast';

import { FileType, GridFields, MemberData, UserRoleType } from 'app/models';

import block from 'utils/bem-css-modules';
import { parseMemberData } from 'utils/helpers/commandCenterHelper';
import { formatMessage } from 'utils/messages/utils';

import UserManagementDialog from './UserManagementDialog';
import style from './UserManagementPanel.module.pcss';
import buildUserManagementPanelColumnDefs from './UserManagementPanelColumnDefs';
import UserManagementPanelHeader from './UserManagementPanelHeader';

const b = block(style);

interface UserManagementPanelProps {
  getMemberCount: () => Promise<QueryResult<GetMemberCount, GetMemberCountVariables>>;
}

const cellRenderer = (cell: ICellRendererParams, userProfile: UserProfile) => {
  // Prevent the logged-in user from interacting with this field.
  if (userProfile?.emailAddress !== cell?.data?.emailAddress) {
    return (
      <div className={b('delete')} data-testid="user-management-delete-user">
        <IconButton
          type={'button'}
          icon={<TrashCan />}
          intent={Intent.DANGER}
          testId={'delete-user-icon'}
          tooltipText={formatMessage('USER_MANAGEMENT_DIALOG_REMOVE_HEADLINE')}
        />
      </div>
    );
  }

  return <div />;
};

export enum ExistingMemberActionModalTypes {
  RevokeContributor = 'RevokeContributor',
  RevokeAdmin = 'RevokeAdmin',
  Delete = 'Delete',
  InviteContributor = 'InviteContributor',
  InviteAdmin = 'InviteAdmin'
}

enum NewMemberActionModalTypes {
  CreateUser = 'CreateUser'
}

const inviteUserToastMessages = {
  [ExistingMemberActionModalTypes.InviteAdmin]: 'USER_MANAGEMENT_ADMIN_ACCESS',
  [ExistingMemberActionModalTypes.InviteContributor]: 'USER_MANAGEMENT_CONTRIBUTOR_ACCESS',
  [ExistingMemberActionModalTypes.RevokeAdmin]: 'USER_MANAGEMENT_ADMIN_REVOKED'
};

export type MemberActionModalState =
  | {
      action: ExistingMemberActionModalTypes;
      member: MemberData;
    }
  | { action: NewMemberActionModalTypes; member: null };

const defaultMemberActionModalState = {
  action: null,
  member: null
};

export const defaultSortModel: SortModelItem[] = [
  {
    colId: GridFields.EMAIL_ADDRESS,
    sort: SortDirection.asc
  }
];

const UserManagementPanel: React.FC<UserManagementPanelProps> = ({ getMemberCount }: UserManagementPanelProps) => {
  const { userProfile } = useUser();
  const { selectedTenant } = useScope();

  const [memberActionModalState, setMemberActionModalState] =
    useState<MemberActionModalState>(defaultMemberActionModalState);
  const [sortModel, setSortModel] = useState<SortModelItem[]>(defaultSortModel);

  const [gridApi, setGridApi] = useState<GridApi>(null);
  const containerRef = React.useRef(null);
  const [blockSize, setBlockSize] = useState<number>(DEFAULT_BLOCK_SIZE);
  const CELL_HEIGHT = 42;
  const [bulkInviteList, setBulkInviteList] = useState<MemberData[]>([]);
  const { showUploadSequenceByFileType } = useFileUpload();

  const showToast = useShowToast();

  const resetMemberActionModalState = () => setMemberActionModalState(defaultMemberActionModalState);

  const defaultGetMemberListVariables: GetMemberListVariables = {
    endRow: blockSize,
    sorting: {
      sortModel
    },
    startRow: 1,
    tenantId: selectedTenant.id
  };

  // Connect to API and collect user management data.
  const [getMemberList, { data: memberList, previousData: previousMemberList, loading: isLoading, fetchMore }] =
    useLazyQuery<GetMemberList, GetMemberListVariables>(GET_MEMBER_LIST, {
      variables: defaultGetMemberListVariables,
      fetchPolicy: 'no-cache',
      onError({ graphQLErrors, networkError }) {
        handleError(graphQLErrors, networkError);
      }
    });

  // Revoke the System Role status of a User.
  const [revokeSystemRole, { loading: revokeLoading }] = useMutation(REVOKE_SYSTEM_ROLE, {
    onCompleted() {
      showToast(formatMessage('USER_MANAGEMENT_MEMBER_REVOKED'), 'success');
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('USER_MANAGEMENT_MEMBER_REVOKED_ERROR'), 'danger');
    }
  });

  // Delete the System Role status of a User.
  const [deleteMember, { loading: deleteLoading }] = useMutation(DELETE_MEMBER, {
    onCompleted() {
      showToast(formatMessage('USER_MANAGEMENT_MEMBER_DELETED'), 'success');
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('USER_MANAGEMENT_MEMBER_DELETED_ERROR'), 'danger');
    }
  });

  // Update the System Role status of a User.
  const [inviteUser, { loading: inviteLoading }] = useMutation(INVITE_USER, {
    onCompleted() {
      showToast(formatMessage(inviteUserToastMessages[memberActionModalState.action]), 'success');
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
      showToast(formatMessage('USER_MANAGEMENT_ACCESS_ERROR'), 'danger');
    }
  });

  // Handle the Revoke action and then update the Member table.
  const handleRevokeContributorAction = async () => {
    const { memberId } = memberActionModalState.member;

    await revokeSystemRole({
      variables: {
        memberId
      }
    });

    await getMemberList();
    resetMemberActionModalState();
  };

  // Handle the Delete action and then update the Member table.
  const handleDeleteAction = async () => {
    const { memberId } = memberActionModalState.member;

    await deleteMember({
      variables: {
        memberId
      },
      refetchQueries: [
        {
          query: GET_MEMBER_LIST,
          variables: {
            ...defaultGetMemberListVariables,
            endRow: 1
          }
        }
      ]
    });

    await getMemberList();
    resetMemberActionModalState();
  };

  const handleInviteAction = async (userRole) => {
    const { emailAddress, firstName, lastName } = memberActionModalState.member;

    await inviteUser({
      variables: {
        emailAddress,
        firstName,
        lastName,
        tenantId: selectedTenant?.id,
        systemRoleName: userRole
      }
    });

    await getMemberList();
    resetMemberActionModalState();
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.columnApi.applyColumnState({ state: sortModel });
    setGridApi(params?.api);
    params.api.deselectAll();
  };

  const onSortChanged = async (params) => setSortModel(params.api.getSortModel() as SortModelItem[]);

  // Render the Member List.
  const { parsedMemberList, totalCount } = useMemo(() => {
    const listToUse = memberList ?? previousMemberList;
    const members = listToUse?.getMemberList?.memberList?.map(parseMemberData) ?? [];
    const total = listToUse?.getMemberList?.totalCount || 0;

    return { parsedMemberList: members, totalCount: total };
  }, [previousMemberList, memberList]);

  // Render the Member List.
  useEffect(() => {
    getMemberList();
  }, []);

  // wait until the sheets grid to render before calculating data table grid block size
  useEffect(() => {
    if (memberList?.getMemberList?.memberList?.length > 0) {
      setBlockSize(Math.round((containerRef?.current?.offsetHeight / CELL_HEIGHT) * 2));
    }
  }, [memberList]);

  useEffect(() => {
    if (totalCount && gridApi) {
      gridApi.setRowCount(totalCount); // set the vertical scroll for ag-grid
    }
  }, [totalCount, gridApi]);

  // Initialize the Default Column Definitions.
  const userManagementPanelGridOptions: GridOptions = {
    defaultColDef: {
      resizable: true
    },
    // eslint-disable-next-line no-restricted-syntax
    rowModelType: 'infinite',
    cacheBlockSize: blockSize,
    cacheOverflowSize: 2,
    infiniteInitialRowCount: totalCount,
    maxBlocksInCache: 10,
    getRowNodeId: (row) => row.emailAddress,
    datasource: {
      rowCount: totalCount,
      getRows: async (params) => {
        if (params?.endRow === blockSize) {
          // no need to query for the first block as we already fetched it in the initial load
          params.successCallback(parsedMemberList, totalCount);
        } else if (getPaginationCheck(params.startRow, totalCount)) {
          const fetchMoreMembers = await fetchMore<GetMemberList, GetMemberListVariables>({
            variables: {
              ...defaultGetMemberListVariables,
              startRow: params.startRow + 1,
              endRow: params.endRow,
              sorting: {
                sortModel
              }
            }
          });
          params?.successCallback(
            fetchMoreMembers?.data?.getMemberList?.memberList.map((member) => parseMemberData(member)),
            fetchMoreMembers?.data?.getMemberList?.totalCount
          );
        }
      }
    }
  };

  const onProcessingCompleted = () => {
    getMemberList();
    // refetch getMemberList to render latest participant count
    getMemberCount();
  };

  const handleSelectionChange = (event: SelectionChangedEvent) => setBulkInviteList(event.api.getSelectedRows());
  const handleClearSelection = () => gridApi.deselectAll();

  const isOnlyParticipantsSelected = useMemo(() => {
    // eslint-disable-next-line no-restricted-syntax
    return bulkInviteList.every((member) => member.systemRoleName === UserRoleType.NONE);
  }, [bulkInviteList]);

  const isOnlyContributorsSelected = useMemo(() => {
    // eslint-disable-next-line no-restricted-syntax
    return bulkInviteList.every((member) => member.systemRoleName === UserRoleType.CONTRIBUTOR);
  }, [bulkInviteList]);

  return (
    <div className={`ag-theme-alpine ${b()}`} ref={containerRef}>
      <UserManagementPanelHeader
        data-testid="user-management-panel-header"
        selectionCount={bulkInviteList.length}
        onDeselect={handleClearSelection}
        totalCount={totalCount}
        isRefreshButtonLoading={isLoading}
        onAddUserButtonClick={() =>
          setMemberActionModalState({ action: NewMemberActionModalTypes.CreateUser, member: null })
        }
        getMemberList={getMemberList}
        bulkInviteList={bulkInviteList}
        isContributorInvitationEnabled={isOnlyParticipantsSelected}
        isAdminInvitationEnabled={isOnlyContributorsSelected || isOnlyParticipantsSelected}
      />
      <div className={b('gridContainer', { loading: isLoading })}>
        {(isLoading || parsedMemberList.length > 0) && (
          <AdvancedGrid
            gridOptions={userManagementPanelGridOptions}
            columnDefs={buildUserManagementPanelColumnDefs({
              userProfile,
              cellRenderer,
              setMemberActionModalState
            })}
            data={JSON.stringify(parsedMemberList)}
            data-testid="user-management-grid"
            gridWidth={containerRef?.current?.offsetWidth}
            gridHeight={containerRef?.current?.offsetHeight}
            onGridReady={onGridReady}
            showGridLoading={isLoading}
            onSelectionChanged={handleSelectionChange}
            rowSelection="multiple"
            rowMultiSelectWithClick={true}
            suppressRowClickSelection={true}
            onSortChanged={onSortChanged}
          />
        )}
      </div>
      {memberActionModalState.action === ExistingMemberActionModalTypes.RevokeContributor && (
        <UserManagementDialog
          data-testid="user-management-dialog-revoke"
          closeDialog={resetMemberActionModalState}
          loading={revokeLoading}
          handleAction={handleRevokeContributorAction}
          headlineText={formatMessage('USER_MANAGEMENT_DIALOG_REVOKE_HEADLINE')}
          bodyText={formatMessage('USER_MANAGEMENT_DIALOG_REVOKE_BODY')}
          actionButtonText={formatMessage('REVOKE')}
          actionButtonIntent="danger"
        />
      )}

      {memberActionModalState.action === ExistingMemberActionModalTypes.RevokeAdmin && (
        <UserManagementDialog
          data-testid="user-management-dialog-admin-revoke"
          closeDialog={resetMemberActionModalState}
          loading={false}
          handleAction={() => handleInviteAction(UserRoleType.CONTRIBUTOR)}
          headlineText={formatMessage('USER_MANAGEMENT_DIALOG_REVOKE_ADMIN_HEADLINE')}
          bodyText={formatMessage('USER_MANAGEMENT_DIALOG_REVOKE_ADMIN_BODY')}
          actionButtonText={formatMessage('REVOKE')}
          actionButtonIntent="danger"
        />
      )}

      {memberActionModalState.action === ExistingMemberActionModalTypes.Delete && (
        <UserManagementDialog
          data-testid="user-management-dialog-delete"
          closeDialog={resetMemberActionModalState}
          loading={deleteLoading}
          handleAction={handleDeleteAction}
          headlineText={formatMessage('USER_MANAGEMENT_DIALOG_REMOVE_HEADLINE')}
          bodyText={formatMessage('USER_MANAGEMENT_DIALOG_REMOVE_BODY')}
          actionButtonText={formatMessage('REMOVE')}
          actionButtonIntent="danger"
        />
      )}

      {memberActionModalState.action === ExistingMemberActionModalTypes.InviteContributor && (
        <UserManagementDialog
          data-testid="user-management-dialog-invite"
          closeDialog={resetMemberActionModalState}
          loading={inviteLoading}
          handleAction={() => handleInviteAction(UserRoleType.CONTRIBUTOR)}
          headlineText={formatMessage('USER_MANAGEMENT_DIALOG_INVITE_HEADLINE')}
          bodyText={formatMessage('USER_MANAGEMENT_DIALOG_INVITE_BODY')}
          actionButtonText={formatMessage('INVITE')}
          actionButtonIntent="primary"
        />
      )}

      {memberActionModalState.action === ExistingMemberActionModalTypes.InviteAdmin && (
        <UserManagementDialog
          data-testid="user-management-dialog-admin-invite"
          closeDialog={resetMemberActionModalState}
          loading={false}
          handleAction={() => handleInviteAction(UserRoleType.ADMIN)}
          headlineText={formatMessage('USER_MANAGEMENT_DIALOG_ADMIN_INVITE_HEADLINE')}
          bodyText={formatMessage('USER_MANAGEMENT_DIALOG_ADMIN_INVITE_BODY')}
          actionButtonText={formatMessage('INVITE')}
          actionButtonIntent="primary"
        />
      )}

      {showUploadSequenceByFileType[FileType.PARTICIPANT] && (
        <FileUploadDialog
          dialogTitle={formatMessage('ADD_USERS')}
          data-testid="file-upload-dialog"
          fileUploadType={FileType.PARTICIPANT}
          onProcessingCompleted={onProcessingCompleted}
        />
      )}

      {memberActionModalState.action === NewMemberActionModalTypes.CreateUser && (
        <CreateUserDialog
          onClose={resetMemberActionModalState}
          data-testid="create-user-dialog"
          onComplete={getMemberList}
        />
      )}

      {!isLoading && parsedMemberList.length === 0 && (
        <div className={b('noMembers')} data-testid="user-management-error">
          {formatMessage('EMPTY_GRID')}
        </div>
      )}
    </div>
  );
};

export default UserManagementPanel;
