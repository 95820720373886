import React, { useState } from 'react';

import { ChevronDown } from '@carbon/icons-react';
import { DividerV2, Text } from '@varicent/components';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import { CustomHierarchyFilterMenuV2 } from 'app/components/TerritoryMap/CustomHierarchyFilterMenuV2';
import SegmentEditorDialog from 'app/components/TerritoryMap/SegmentEditorDialog/SegmentEditorDialog';
import SegmentPicker from 'app/components/TerritoryMap/TerritoryMapGrid/SegmentPicker';

import { CollectionFilter, NamedHierarchy } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './MapGridFilterV2.module.pcss';

const b = block(style);

interface MapGridFilterProps {
  customHierarchies: NamedHierarchy[];
  customHierarchyFilter: CollectionFilter<number>;
}
const MapGridFilterV2: React.FC<MapGridFilterProps> = ({ customHierarchies, customHierarchyFilter }) => {
  const [isMapGridFilterOpen, setIsMapGridFilterOpen] = useState(false);
  const [isSegmentEditorOpen, setIsSegmentEditorOpen] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState('Hierarchies');

  const toggleSegmentEditor = () => {
    setIsSegmentEditorOpen((prevIsSegmentEditorOpen) => !prevIsSegmentEditorOpen);
    setIsMapGridFilterOpen(false);
  };

  return (
    <div>
      <Popover
        onInteraction={(nextOpenState) => {
          if (!nextOpenState) setIsMapGridFilterOpen(false);
        }}
        isOpen={isMapGridFilterOpen}
        onClosed={() => setIsMapGridFilterOpen(false)}
        onOpened={() => setIsMapGridFilterOpen(true)}
        content={
          <div className={b('filterSelectionWrapper')}>
            <Text className={b('filterMapsTitle')}>{formatMessage('FILTER_MAPS_BY')}</Text>
            <SegmentPicker
              selectedSegment={selectedSegment}
              setSelectedSegment={(selectedSegment) => setSelectedSegment(selectedSegment)}
            />
            <DividerV2 />
            <div className={b('customHierarchyFilterMenuWrapper')}>
              <CustomHierarchyFilterMenuV2
                showFooter={true}
                filter={customHierarchyFilter}
                hierarchies={customHierarchies}
                onOpenSegmentEditor={toggleSegmentEditor}
              />
            </div>
          </div>
        }
        minimal
        placement="bottom-end"
      >
        <TextButton
          text={selectedSegment}
          type="button"
          minimal
          large={false}
          rightIcon={<ChevronDown />}
          testId="filter-button"
          onClick={() => setIsMapGridFilterOpen((prevIsMapGridFilterOpen) => !prevIsMapGridFilterOpen)}
          active={isMapGridFilterOpen}
        />
      </Popover>
      {isSegmentEditorOpen && (
        <SegmentEditorDialog
          hierarchies={customHierarchies}
          isOpen={isSegmentEditorOpen}
          onClose={toggleSegmentEditor}
          filter={customHierarchyFilter}
        />
      )}
    </div>
  );
};

export default MapGridFilterV2;
