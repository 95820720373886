import React, { useEffect, useState } from 'react';

import { RowNode } from '@ag-grid-community/core';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@apollo/client';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import buildActivityDrillInColumnDef from 'app/components/AdvancedGrid/GridHelpers/ActivityDrillIn/ActivityDrillInColumnDef';

import { CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { SHOW_LOADING_AFTER } from 'app/global/variables';

import { handleError } from 'app/graphql/handleError';
import { GET_ACTIVITIES_FOR_TERRITORY } from 'app/graphql/queries/getActivitiesForTerritory';
import { GET_ACTIVITIES_FOR_TERRITORY_GROUP } from 'app/graphql/queries/getActivitiesForTerritoryGroup';
import { GET_UNASSIGNED_ACTIVITIES } from 'app/graphql/queries/getUnassignedActivities';

import useShowToast from 'app/hooks/useShowToast';

import { ActivityDrillInColumnNames } from 'app/models/index';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './ActivitiesGrid.module.pcss';

const b = block(style);
interface ActivitiesGridProps {
  isUnassignedSelected: boolean;
}

const ActivitiesGrid: React.FC<ActivitiesGridProps> = ({ isUnassignedSelected }: ActivitiesGridProps) => {
  const { isActivityCountSelected, selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const { selectedBattleCardId, selectedQuotaComponentId } = useBattleCard();
  const { refreshGrid, setRefreshGrid, selectedTerritory } = useGrid();
  const { defaultReportingCurrency } = useLocalization();
  const showToast = useShowToast();

  const [isLoading, setIsLoading] = useState(false);

  const containerRef = React.useRef(null);

  const { data: activitiesForTerritory, loading: activitiesForTerritoryLoading } = useQuery(
    GET_ACTIVITIES_FOR_TERRITORY,
    {
      fetchPolicy: 'network-only',
      skip: isActivityCountSelected || isUnassignedSelected,
      variables: {
        territoryId: selectedTerritory?.territoryId,
        territoryGroupId: selectedTerritory?.territoryGroupId,
        quotaComponentId: selectedQuotaComponentId,
        startRow: 1,
        endRow: 200
      },
      onError({ graphQLErrors, networkError }) {
        showToast(formatMessage('GET_ACTIVITIES_FOR_TERRITORY_ERROR'), 'danger');
        handleError(graphQLErrors, networkError);
      }
    }
  );

  const { data: activitiesForTerritoryGroup, loading: activitiesForTerritoryGroupLoading } = useQuery(
    GET_ACTIVITIES_FOR_TERRITORY_GROUP,
    {
      fetchPolicy: 'network-only',
      skip: !isActivityCountSelected || isUnassignedSelected,
      variables: {
        quotaComponentId: selectedQuotaComponentId,
        territoryGroupId: +selectedPillIdTDR,
        startRow: 1,
        endRow: 200
      },
      onError({ graphQLErrors, networkError }) {
        showToast(formatMessage('GET_ACTIVITIES_FOR_TERRITORY_GROUP_ERROR'), 'danger');
        handleError(graphQLErrors, networkError);
      }
    }
  );

  const { data: unassignedActivities, loading: unassignedActivitiesLoading } = useQuery(GET_UNASSIGNED_ACTIVITIES, {
    fetchPolicy: 'network-only',
    skip: !isUnassignedSelected,
    variables: {
      battlecardId: +selectedBattleCardId,
      quotaComponentId: selectedQuotaComponentId,
      startRow: 1,
      endRow: 200
    },
    onError({ graphQLErrors, networkError }) {
      showToast(formatMessage('GET_UNASSIGNED_ACTIVITIES_ERROR'), 'danger');
      handleError(graphQLErrors, networkError);
    }
  });

  const handleModelUpdate = (gridEvent) => {
    const pinnedBottomTotals = {};
    const measures = [ActivityDrillInColumnNames.SALES];
    measures.forEach((measureName) => {
      const pinnedBottomData = getPinnedBottomData(gridEvent, measureName);
      pinnedBottomTotals[measureName] = +pinnedBottomData;
    });
    gridEvent.api.setPinnedBottomRowData([pinnedBottomTotals]);
  };

  const getPinnedBottomData = (gridEvent, measureName: string) => {
    let total = 0;

    gridEvent.api.forEachNodeAfterFilter((rowNode: RowNode) => {
      const measureValue = rowNode.data[measureName];
      if (measureValue) {
        total += +measureValue;
      }
    });

    return total;
  };

  useEffect(() => {
    if (refreshGrid) {
      setRefreshGrid(false);
    }
  }, [refreshGrid]);

  useEffect(() => {
    const timeoutTerritory = setTimeout(() => {
      if (
        activitiesForTerritoryLoading ||
        unassignedActivitiesLoading ||
        activitiesForTerritoryGroupLoading ||
        refreshGrid
      ) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }, SHOW_LOADING_AFTER);

    return () => {
      clearTimeout(timeoutTerritory);
    };
  }, [activitiesForTerritoryLoading, unassignedActivitiesLoading, activitiesForTerritoryGroupLoading, refreshGrid]);

  const gridOptions = {
    headerHeight: CELL_HEIGHT,
    rowHeight: 40,
    rowClass: style.DataTray_gridRow,
    suppressScrollOnNewData: true,
    suppressAnimationFrame: true,
    suppressColumnMoveAnimation: true,
    rowSelection: 'single',
    onModelUpdated: handleModelUpdate
  };

  return (
    <div className={b()} data-testid="activities-container" ref={containerRef}>
      <div className={b('territoryDetails')}>
        <div className={b('territoryDetailsGrid')}>
          {(() => {
            if (activitiesForTerritory || unassignedActivities || activitiesForTerritoryGroup) {
              const activityData =
                activitiesForTerritory?.getActivitiesForTerritory?.territoryActivitiesList ||
                unassignedActivities?.getUnassignedActivities?.territoryActivitiesList ||
                activitiesForTerritoryGroup?.getActivitiesForTerritoryGroup?.territoryActivitiesList;
              let activityDataString = null;
              if (activityData?.length) {
                activityDataString = JSON.stringify(activityData?.map(JSON.parse));
              }
              // show activities grid
              return (
                <AdvancedGrid
                  gridOptions={gridOptions}
                  columnDefs={buildActivityDrillInColumnDef(activityData, defaultReportingCurrency)}
                  data={activityDataString}
                  data-testid="activities-grid"
                  gridHeight={containerRef?.current?.offsetHeight}
                  gridWidth={containerRef?.current?.offsetWidth}
                  showGridLoading={isLoading}
                  noDataMessage={formatMessage('NO_ACTIVITY_FILES')}
                />
              );
            } else {
              return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default ActivitiesGrid;
