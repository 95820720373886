import React from 'react';

import { Search, CollapseAll, ExpandAll } from '@carbon/icons-react';
import { DividerV2 } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';

import SortMenu from 'app/components/TerritoryMap/SortMenu/SortMenu';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { SplitFeatures } from 'app/global/features';

import { SortDirection } from 'app/graphql/generated/graphqlApolloTypes';

import useTreatment from 'app/hooks/useTreatment';

import { CollectionFilter, MchQuantity, NamedHierarchy, PrimaryTerritoryField } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import ExpandableCustomHierarchyTags from './ExpandableCustomHierarchyTags';
import MapGridFilter from './MapGridFilter';
import MapGridFilterV2 from './MapGridFilterV2';
import style from './MapGridHeader.module.pcss';

const b = block(style);

interface MapGridHeaderProps {
  onSearchIconClick: () => void;
  customHierarchies: NamedHierarchy[];
  customHierarchyFilter: CollectionFilter<number>;
  onCollapseRows?: () => void;
  onExpandRows?: () => void;
  showCollapseAndExpandButtons?: boolean;
  primaryTerritoryField?: PrimaryTerritoryField;
  onPrimaryTerritoryFieldChange?: (field: PrimaryTerritoryField) => void;
  sortOrder: SortDirection;
  onSortOrderChange: (sortOrder: SortDirection) => void;
}

const MapGridHeader: React.FC<MapGridHeaderProps> = ({
  onSearchIconClick,
  customHierarchies,
  customHierarchyFilter,
  onCollapseRows,
  onExpandRows,
  primaryTerritoryField,
  onPrimaryTerritoryFieldChange,
  showCollapseAndExpandButtons = false,
  sortOrder,
  onSortOrderChange
}) => {
  const { chosenCustomHierarchy } = useDedicatedMapProvider();
  const [isMapSegmentationEnabled] = useTreatment(SplitFeatures.MAP_SEGMENTATION);

  const isCustomHierarchiesEnabled = chosenCustomHierarchy.quantity !== MchQuantity.NONE;

  const expandAndCollapseButtons = () => {
    if (!showCollapseAndExpandButtons) return null;

    return (
      <>
        <DividerV2 vertical className={b('divider')} />
        <IconButton
          type="button"
          icon={<CollapseAll />}
          testId="collapse-all-button"
          minimal
          onClick={onCollapseRows}
          tooltipText={formatMessage('COLLAPSE_ALL')}
        />
        <IconButton
          type="button"
          icon={<ExpandAll />}
          testId="expand-all-button"
          minimal
          onClick={onExpandRows}
          tooltipText={formatMessage('EXPAND_ALL')}
        />
      </>
    );
  };

  return (
    <div className={b()}>
      <div className={b('visualizationContainer')}>
        <div className={b('iconsContainer')}>
          <IconButton type="button" icon={<Search />} testId="search-icon-button" minimal onClick={onSearchIconClick} />
          {isMapSegmentationEnabled && (
            <SortMenu
              onPrimaryTerritoryFieldChange={onPrimaryTerritoryFieldChange}
              primaryTerritoryField={primaryTerritoryField}
              sortOrder={sortOrder}
              onSortOrderChange={onSortOrderChange}
            />
          )}
          {expandAndCollapseButtons()}
        </div>

        {isMapSegmentationEnabled && chosenCustomHierarchy.quantity !== MchQuantity.NONE && (
          <MapGridFilterV2 customHierarchyFilter={customHierarchyFilter} customHierarchies={customHierarchies} />
        )}
        {!isMapSegmentationEnabled && (
          <MapGridFilter
            customHierarchyFilter={customHierarchyFilter}
            customHierarchies={customHierarchies}
            shouldShowCustomHierarchyFilter={isCustomHierarchiesEnabled}
            primaryTerritoryField={primaryTerritoryField}
            onPrimaryTerritoryFieldChange={onPrimaryTerritoryFieldChange}
          />
        )}
      </div>
      {isMapSegmentationEnabled && customHierarchyFilter.ids.length > 0 && (
        <ExpandableCustomHierarchyTags
          customHierarchies={customHierarchies}
          customHierarchyFilter={customHierarchyFilter}
        />
      )}
    </div>
  );
};

export default MapGridHeader;
