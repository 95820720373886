import { useEffect, useState } from 'react';

import debounce from 'lodash.debounce';
import { useMap } from 'react-map-gl';

import { getViewFromMap } from 'utils/helpers/territoryMapUtils';

export const useApproximateMapView = (debounceDuration = 100) => {
  const mapboxMapRef = useMap();
  const [view, setView] = useState(() => getViewFromMap(mapboxMapRef.current));

  useEffect(() => {
    const moveHandler = () => {
      setView(getViewFromMap(mapboxMapRef.current));
    };
    const debouncedMoveHandler = debounce(moveHandler, debounceDuration);
    mapboxMapRef.current.on('move', debouncedMoveHandler);
    return () => {
      mapboxMapRef.current.off('move', debouncedMoveHandler);
    };
  }, [debounceDuration]);

  useEffect(() => {
    const loadHandler = () => {
      setView((existingView) => existingView ?? getViewFromMap(mapboxMapRef.current));
    };
    loadHandler();
    mapboxMapRef.current.once('load', loadHandler);
    return () => {
      mapboxMapRef.current.off('load', loadHandler);
    };
  }, []);
  return view;
};
