import { useCallback, useState } from 'react';

import { SortDirection } from 'app/graphql/generated/graphqlApolloTypes';

import { createScopedLocalStorage } from 'utils/helpers/localStorageHelpers';

const storage = createScopedLocalStorage<SortDirection>('Map_GridSortOrder');

export const useMapGridSortOrder = (): [SortDirection, (direction: SortDirection) => void] => {
  const [field, setField] = useState<SortDirection>(() => storage.read() ?? SortDirection.asc);

  const setFieldState = useCallback((field) => {
    setField(field);
    storage.write(field);
  }, []);

  return [field, setFieldState];
};
