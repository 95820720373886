import { useCallback, useEffect, useRef } from 'react';

import { SetFeatureStateMessage } from 'app/workers/MapWorker/MapWorkerProtocol';
import type { Map as MapboxMap } from 'mapbox-gl';

import { useOnMapWorkerMessage } from 'app/contexts/mapWorkerContext';

import { FeatureStateIds, PolygonFeatureState } from 'app/models';

// For Feature state null and undefined should be treated as identical
const isFeatureStateValueEqual = (a: unknown, b: unknown) => {
  if (a == null && b == null) return true;
  return a === b;
};

const createPatch = (oldState: PolygonFeatureState, newState: PolygonFeatureState): PolygonFeatureState | null => {
  let hasDelta = false;
  const patch: PolygonFeatureState = {};
  for (const key in newState) {
    if (!isFeatureStateValueEqual(oldState[key], newState[key])) {
      hasDelta = true;
      patch[key] = newState[key];
    }
  }
  if (hasDelta) return patch;
  return null;
};

const applyFeatureState = (map: MapboxMap, ids: FeatureStateIds, newState: PolygonFeatureState) => {
  try {
    const oldState = map.getFeatureState(ids);
    const patch = createPatch(oldState, newState);
    if (patch) map.setFeatureState(ids, patch);
  } catch (error) {
    console.error(error);
  }
};

export const useApplyFeatureState = (mapboxMap: MapboxMap, areSourcesLoading: boolean): void => {
  const bufferRef = useRef<SetFeatureStateMessage[]>([]);
  const shouldPlaceInBuffer = !mapboxMap || areSourcesLoading;

  const applyMessage = useCallback(
    (message: SetFeatureStateMessage) => {
      if (!mapboxMap) {
        console.warn('cannot apply feature state without a map to apply to ');
        return;
      }

      message.bulk?.ids.forEach((id) => applyFeatureState(mapboxMap, id, message.bulk.state));
      message.specials?.forEach(({ id, state }) => applyFeatureState(mapboxMap, id, state));
    },
    [mapboxMap]
  );

  const handleSetFeatureStateMessage = useCallback(
    (message) => {
      if (message.type !== 'set-feature-state') return;

      if (!message.bulk?.ids.length && !message.specials?.length) return;
      if (shouldPlaceInBuffer) {
        bufferRef.current.push(message);
        return;
      }
      applyMessage(message);
    },
    [shouldPlaceInBuffer, applyMessage]
  );

  useOnMapWorkerMessage(handleSetFeatureStateMessage);

  useEffect(() => {
    if (shouldPlaceInBuffer) return;
    bufferRef.current.forEach((message) => applyMessage(message));
    bufferRef.current.length = 0;
  }, [shouldPlaceInBuffer, applyMessage]);
};
