// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uA2x0 {\n  width: 100%;\n}\n\n  ._0e3bd {\n    margin-bottom: var(--m);\n  }\n\n  ._1S0\\+C {\n    width: 248px;\n  }\n\n  .LR3JE {\n    margin: 0 !important;\n  }\n\n  .QNyqz {\n    padding: 8px 4px;\n  }\n\n  .u50nk {\n    width: 100%;\n  }\n\n  .b6uA- {\n    display: flex;\n    justify-content: space-between;\n    color: var(--text) !important;\n    width: 248px;\n  }\n\n  .scJJz {\n    font-weight: 600;\n    color: var(--text-heading) !important;\n    pointer-events: none;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SegmentPicker": "uA2x0",
	"SegmentPicker_segmentSelectionDropdown": "_0e3bd",
	"SegmentPicker_segmentSelectionMenu": "_1S0+C",
	"SegmentPicker_divider": "LR3JE",
	"SegmentPicker_createSegmentButtonWrapper": "QNyqz",
	"SegmentPicker_createSegmentButton": "u50nk",
	"SegmentPicker_segmentDropdownButton": "b6uA-",
	"SegmentPicker_segmentMenuItemLabel": "scJJz"
};
export default ___CSS_LOADER_EXPORT___;
