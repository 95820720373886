import { useCallback, useEffect } from 'react';

import { FromMapWorkerMessage } from 'app/workers/MapWorker/MapWorkerProtocol';
import { useMap } from 'react-map-gl';

import { SourceIds } from 'app/components/TerritoryMap/MapStyleTokens';

import { useMapWorkerPostMessage, useOnMapWorkerMessage } from 'app/contexts/mapWorkerContext';

import { useIsMapSourcePresent } from './useIsMapItemPresent';

export function useApplyAccountFeatureState() {
  const mapboxMapRef = useMap();

  const handleMessage = useCallback((message: FromMapWorkerMessage) => {
    if (message.type !== 'set-accounts-is-selected') return;
    if (!mapboxMapRef.current) return;
    const state = { isSelected: message.isSelected };
    for (const id of message.ids) {
      mapboxMapRef.current.setFeatureState({ id, source: SourceIds.account.cluster }, state);
    }
  }, []);
  useOnMapWorkerMessage(handleMessage);
  useGetInitialSelectedAccounts(SourceIds.account.cluster);
}

const useGetInitialSelectedAccounts = (waitForSourceId: string) => {
  const mapboxMapRef = useMap();
  const isSourceReady = useIsMapSourcePresent(mapboxMapRef.current, waitForSourceId);
  const postMessage = useMapWorkerPostMessage();
  useEffect(() => {
    if (isSourceReady) postMessage({ type: 'get-initial-selected-accounts' });
  }, [isSourceReady]);
};
