import React, { useMemo } from 'react';

import { SortAscending } from '@carbon/icons-react';
import { Divider, Intent } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import SwitchButton from 'components/Buttons/SwitchButton/SwitchButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';
import ToastMessage from 'components/ToastMessage/ToastMessage';

import FormRadioGroup from 'app/components/FormFields/FormRadioGroup/FormRadioGroup';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { SortDirection } from 'app/graphql/generated/graphqlApolloTypes';

import useShowToast from 'app/hooks/useShowToast';

import { PrimaryTerritoryField } from 'app/models';

import block from 'utils/bem-css-modules';
import { createScopedSessionStorage } from 'utils/helpers/sessionStorageHelpers';
import { getTerritoryGroupLevelName } from 'utils/helpers/territoryMapUtils';
import { formatMessage } from 'utils/messages/utils';

import style from './SortMenu.module.pcss';

const b = block(style);

interface SortMenuProps {
  onPrimaryTerritoryFieldChange: (field: PrimaryTerritoryField) => void;
  primaryTerritoryField: PrimaryTerritoryField;
  sortOrder: SortDirection;
  onSortOrderChange: (sortOrder: SortDirection) => void;
}

const suppressColorByToastStorage = createScopedSessionStorage<boolean>('MAP_HIDE_COLOR_BY_TERRITORY_GROUP_TOAST');

const SortMenu: React.FC<SortMenuProps> = ({
  onPrimaryTerritoryFieldChange,
  primaryTerritoryField,
  sortOrder = SortDirection.asc,
  onSortOrderChange
}) => {
  const {
    groupLevelList,
    territoryGroupLevel,
    setTerritoryGroupLevel,
    setIsColoringByTerritoryGroup,
    isColoringByTerritoryGroup
  } = useDedicatedMapProvider();
  const showToast = useShowToast();

  const groupLevelMenuItems: { label: string; value: string }[] = useMemo(
    () => [
      ...groupLevelList
        .filter((group) => group.levelIndex !== 0)
        .map(({ levelIndex }) => ({
          label: getTerritoryGroupLevelName(levelIndex, true),
          value: levelIndex.toString()
        })),
      { label: getTerritoryGroupLevelName(null, true), value: '0' }
    ],
    [groupLevelList]
  );

  const saveShouldHideColorByTerritoryGroupToast = () => {
    suppressColorByToastStorage.write(true);
  };

  const handleColorByTerritoryGroupsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsColoringByTerritoryGroup(event.currentTarget.checked);

    if (event.currentTarget.checked && !suppressColorByToastStorage.read()) {
      showToast(
        <ToastMessage
          title={formatMessage('COLOR_BY_TERRITORY_GROUP_TOAST_TITLE')}
          message={
            <div>
              {formatMessage('COLOR_BY_TERRITORY_GROUP_TOAST_MESSAGE')}
              <TextButton
                onClick={saveShouldHideColorByTerritoryGroupToast}
                className={b('colorByTerritoryToastButton')}
                minimal
                type="button"
                testId="dont-show-me-this-again-button"
                text={formatMessage('DONT_SHOW_THIS_AGAIN')}
              />
            </div>
          }
        />,
        Intent.PRIMARY
      );
    }
  };

  return (
    <Popover
      placement="bottom-end"
      minimal
      content={
        <div className={b()}>
          <div className={b('sortMenuContainer')}>
            <div className={b('sortMenuTitle')}>{formatMessage('GROUP_BY_WITHOUT_COLON')}</div>
            <FormRadioGroup
              inline={false}
              name="mapGroupBy"
              radioOptions={groupLevelMenuItems}
              setSelectedRadio={(option) =>
                option === '0' ? setTerritoryGroupLevel(null) : setTerritoryGroupLevel(parseInt(option))
              }
              selectedRadio={territoryGroupLevel ? territoryGroupLevel.toString() : '0'}
            />
          </div>
          <Divider />
          <div className={b('sortMenuContainer')}>
            <SwitchButton
              checked={isColoringByTerritoryGroup}
              onChange={handleColorByTerritoryGroupsChange}
              labelElement={
                <>
                  <span data-testid="color-by-territory-group-label">{formatMessage('COLOR_BY_TERRITORY_GROUP')}</span>
                  <br />
                  <span data-testid="color-by-territory-group-label-view-only">
                    {formatMessage('COLOR_BY_TERRITORY_GROUP_VIEW_ONLY')}
                  </span>
                </>
              }
              intent="primary"
              testId="color-by-territory-group-switch"
              disabled={territoryGroupLevel === null}
            />
          </div>
          <Divider />
          <div className={b('sortMenuContainer')}>
            <div className={b('sortMenuTitle')}>{formatMessage('SORT_BY')}</div>
            <FormRadioGroup
              inline={false}
              name="mapSortBy"
              radioOptions={[
                { label: formatMessage('TERRITORY_ID'), value: 'territoryId' },
                { label: formatMessage('TERRITORY_NAME'), value: 'territoryName' }
              ]}
              setSelectedRadio={(option) => onPrimaryTerritoryFieldChange(option as PrimaryTerritoryField)}
              selectedRadio={primaryTerritoryField}
            />
          </div>
          <Divider />
          <div className={b('sortMenuContainer')}>
            <FormRadioGroup
              inline={false}
              name="mapSortOrder"
              radioOptions={[
                { label: formatMessage('ASCENDING'), value: SortDirection.asc },
                { label: formatMessage('DESCENDING'), value: SortDirection.desc }
              ]}
              setSelectedRadio={(option) => onSortOrderChange(option as SortDirection)}
              selectedRadio={sortOrder}
            />
          </div>
        </div>
      }
    >
      <IconButton
        testId="sort-menu-button"
        tooltipText={formatMessage('SORT_MENU')}
        minimal
        type="button"
        icon={<SortAscending />}
        data-testid="map-grid-sort-button"
      />
    </Popover>
  );
};

export default SortMenu;
