// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u9fIv {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n  ._4\\+XjQ .bp3-skeleton {\n      width: 160px;\n      height: 20px;\n    }\n\n  ._8EGR7 {\n    justify-content: space-between;\n    grid-gap: var(--xs);\n    gap: var(--xs);\n    display: flex;\n    padding: var(--s) 0;\n  }\n\n  .DTFAq {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .zFEUI {\n    font-weight: bold;\n    font-size: 14px;\n    margin-left: var(--s);\n  }\n\n  ._5Osys {\n    font-size: 14px;\n    margin-left: var(--s);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapGridTitleV2": "u9fIv",
	"MapGridTitleV2_loadingSkeleton": "_4+XjQ",
	"MapGridTitleV2_titleWrapper": "_8EGR7",
	"MapGridTitleV2_titleTextWrapper": "DTFAq",
	"MapGridTitleV2_title": "zFEUI",
	"MapGridTitleV2_subTitle": "_5Osys"
};
export default ___CSS_LOADER_EXPORT___;
