import React, { FC, useEffect, useState } from 'react';

import { Report } from '@carbon/icons-react';

import { LINKED_DISTANCE } from 'app/components/TerritoryMap/hooks//useBisectedPanel';
import { useInsightAndGridGutter } from 'app/components/TerritoryMap/hooks/useInsightAndGridGutter';
import MapGridTitle from 'app/components/TerritoryMap/TerritoryMapGrid/MapGridTitle';
import MapGridTitleV2 from 'app/components/TerritoryMap/TerritoryMapGrid/MapGridTitleV2';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { InsightsCardState } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './Gutter.module.pcss';
import { GutterPanel } from './GutterPanel';
import { HeightRuler } from './HeightRuler';

const b = block(style);

const GAP_BETWEEN_PANELS = 4;

// This does not have to strictly match the linked distance, but they should be set with one another in mind
const DEAD_ZONE_THRESHOLD = LINKED_DISTANCE;

const INSIGHTS_HEADER = 40;
const PANEL_DIVIDER = 16;
const INSIGHTS_EXTRA_HEIGHT = INSIGHTS_HEADER + PANEL_DIVIDER;

export interface GutterProps {
  insightsState: InsightsCardState;
  gridContent: React.ReactNode;
  insightsContent: React.ReactNode;
  onChangeInsightsState: (state: InsightsCardState) => void;
}

const GutterContent: FC<GutterProps & { gutterHeight: number }> = ({
  gutterHeight,
  insightsState,
  gridContent,
  insightsContent,
  onChangeInsightsState
}) => {
  const [insightsContentSize, setInsightsContentSize] = useState(0);
  const [isMapSegmentationEnabled] = useTreatment(SplitFeatures.MAP_SEGMENTATION);
  const space = gutterHeight - GAP_BETWEEN_PANELS;
  const [state, action] = useInsightAndGridGutter(space, insightsState, insightsContentSize + INSIGHTS_EXTRA_HEIGHT);
  useEffect(() => {
    if (!state.bottom.isExpanded) onChangeInsightsState(InsightsCardState.Summary);
  }, [state.bottom.isExpanded]);

  return (
    <>
      <div className={b('deadZone', { isActive: isDeadZoneActive(state, space) })} />
      <GutterPanel
        headerContent={isMapSegmentationEnabled ? <MapGridTitleV2 /> : <MapGridTitle />}
        edge="top"
        minimal={true}
        height={state.top.height}
        isExpanded={state.top.isExpanded}
        isResizeEnabled={true}
        onResize={(height) => action({ kind: 'resize-to', height, panel: 'top' })}
        onToggleExpanded={() => action({ kind: 'toggle-expanded', panel: 'top' })}
      >
        {gridContent}
      </GutterPanel>
      <GutterPanel
        headerContent={<InsightsHeader />}
        edge="bottom"
        minimal={false}
        height={state.bottom.height}
        isExpanded={insightsState !== InsightsCardState.None && state.bottom.isExpanded}
        isResizeEnabled={insightsState === InsightsCardState.Table}
        onResize={(height) => action({ kind: 'resize-to', height, panel: 'bottom' })}
        onToggleExpanded={() => action({ kind: 'toggle-expanded', panel: 'bottom' })}
      >
        <HeightRuler
          className={b('insightsContentRuler', { fill: insightsState === InsightsCardState.Table })}
          onResize={setInsightsContentSize}
        >
          {insightsContent}
        </HeightRuler>
      </GutterPanel>
    </>
  );
};

const InsightsHeader = () => (
  <h3 className={b('insightsHeader')}>
    <Report />
    {formatMessage('SELECTION_INSIGHTS')}
  </h3>
);

export const Gutter: FC<GutterProps> = (props) => {
  const [height, setHeight] = useState<number | null>(null);
  const handleResize = (newHeight: number | null) => setHeight((oldHeight) => newHeight ?? oldHeight);

  return (
    <HeightRuler className={b()} onResize={handleResize}>
      {height !== null && <GutterContent {...props} gutterHeight={height} />}
    </HeightRuler>
  );
};

const isDeadZoneActive = (state: { top: { height: number }; bottom: { height: number } }, space: number) =>
  state.top.height + state.bottom.height + DEAD_ZONE_THRESHOLD >= space;
