import React, { forwardRef, useMemo } from 'react';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { AccountFilter, AccountTerritoryFilterOptions, CustomerForMap, CustomerVisualization } from 'app/models';

import { formatPinsAsFilteredFeatureCollection } from 'utils/helpers/territoryMapUtils';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';

import ClusterAccountSourceLayers from './ClusterAccountSourceLayers';
import { GroveHelpersClusterRefHandle, GroveClusterAccountSourceLayers } from './GroveClusterAccountSourceLayers';
import HeatPinAccountSourceLayers from './HeatPinAccountSourceLayers';
import { useIsMapCapable } from './hooks/useMapCapability';

export interface AccountSourceLayersProps {
  customers: ReadonlyArray<CustomerForMap>;
  accountFilter: AccountFilter;
  selectedRuleIds: number[];
  ignoredRuleIds: Set<number>;
  visualizationType: CustomerVisualization.CLUSTER | CustomerVisualization.HEAT;
}

const useIsGroveClusteringOn = () => {
  const [isAccountFirstMapOn] = useTreatment(SplitFeatures.ACCOUNT_FIRST_MAP);
  const [isInHouseClusteringOn] = useTreatment(SplitFeatures.IN_HOUSE_CLUSTERING);
  return isAccountFirstMapOn && isInHouseClusteringOn;
};

export const AccountSourceLayers = forwardRef<GroveHelpersClusterRefHandle, AccountSourceLayersProps>(
  function AccountSourceLayers(
    { customers, accountFilter, selectedRuleIds, ignoredRuleIds, visualizationType }: AccountSourceLayersProps,
    groveHelpersRef
  ) {
    const isGroveClusteringOn = useIsGroveClusteringOn();
    const canViewUnassigned = useIsMapCapable(MapCapability.VIEW_UNASSIGNED);
    const selectedRulesWhenRelevant =
      accountFilter.territory === AccountTerritoryFilterOptions.SELECTED_TERRITORIES ? selectedRuleIds : null;
    const accountFeatures = useMemo(
      () =>
        formatPinsAsFilteredFeatureCollection(
          customers,
          accountFilter,
          selectedRulesWhenRelevant,
          ignoredRuleIds,
          canViewUnassigned
        ),
      [customers, accountFilter, selectedRulesWhenRelevant, ignoredRuleIds, canViewUnassigned]
    );

    switch (visualizationType) {
      case CustomerVisualization.CLUSTER:
        if (isGroveClusteringOn)
          return <GroveClusterAccountSourceLayers accountFeatures={accountFeatures} ref={groveHelpersRef} />;
        return <ClusterAccountSourceLayers features={accountFeatures} />;
      case CustomerVisualization.HEAT:
        return <HeatPinAccountSourceLayers features={accountFeatures} />;
      default:
        console.error(`Invalid visualization type ${visualizationType}`);
        return null;
    }
  }
);
