// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9\\+pAd {\n  display: flex;\n  margin-bottom: var(--s);\n}\n  .fT6Ph {\n    display: none;\n  }\n  ._4Iz4A {\n      height: 24px;\n    }\n  .RADjp {\n    margin-right: var(--xs);\n    margin-bottom: var(--xs);\n    max-width: 250px;\n  }\n  .tcNQd {\n    width: 100%;\n  }\n  .fKuTu {\n    display: flex;\n  }\n  .yKALT {\n    align-self: flex-end;\n  }\n  ._9\\+pAd .bp3-button {\n    height: 24px;\n  }\n  ._9\\+pAd .bp3-button:hover {\n    background-color: transparent;\n  }\n  ._9\\+pAd .bp3-button:active {\n    background-color: transparent !important;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExpandableCustomHierarchyTags": "_9+pAd",
	"ExpandableCustomHierarchyTags_hidden": "fT6Ph",
	"ExpandableCustomHierarchyTags_tagsContainer__hideOverflowTags": "_4Iz4A",
	"ExpandableCustomHierarchyTags_customHierarchyTag": "RADjp",
	"ExpandableCustomHierarchyTags_customHierarchyTagEllipsisText": "tcNQd",
	"ExpandableCustomHierarchyTags_controlButtonsContainer": "fKuTu",
	"ExpandableCustomHierarchyTags_hideOverflowTagsButton": "yKALT"
};
export default ___CSS_LOADER_EXPORT___;
