import React, { useState } from 'react';

import { SettingsAdjust } from '@carbon/icons-react';
// eslint-disable-next-line no-restricted-imports
import { Classes } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';

import { useDynamicMapHierarchyCopy } from 'app/components/TerritoryMap/hooks/useDynamicMapHierarchyCopy';
import { useIsMapCapable } from 'app/components/TerritoryMap/hooks/useMapCapability';
import MapVisualizationSettingsDialog from 'app/components/TerritoryMap/MapVisualizationSettingsDialog/MapVisualizationSettingsDialog';
import MapRuleSettingsDialog from 'app/components/TerritoryMap/TerritoryMapGrid/MapRuleSettingsDialog';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { SplitFeatures } from 'app/global/features';

import useTreatment from 'app/hooks/useTreatment';

import { MchQuantity } from 'app/models';

import block from 'utils/bem-css-modules';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';

import style from './MapGridTitleV2.module.pcss';

const b = block(style);

const MapGridTitleV2: React.FC = () => {
  const [isAccountFirstMapOn] = useTreatment(SplitFeatures.ACCOUNT_FIRST_MAP);
  const { isMapVisualizationSettingsLoading } = useMapContextRedistributor();
  const canViewVisualizationSettings = useIsMapCapable(MapCapability.VIEW_VISUALIZATION_SETTINGS);
  const { chosenCustomHierarchy } = useDedicatedMapProvider();

  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);

  const { mapGridTitleText } = useDynamicMapHierarchyCopy();

  const chosenCustomHierarchyName =
    chosenCustomHierarchy.quantity === MchQuantity.SINGULAR ? chosenCustomHierarchy.details.rootName : null;

  const renderSettingsDialog = () => {
    if (isAccountFirstMapOn) {
      return (
        <MapVisualizationSettingsDialog
          onClose={() => setIsSettingsDialogOpen(false)}
          onComplete={() => setIsSettingsDialogOpen(false)}
          isLastStep={true}
        />
      );
    } else {
      return <MapRuleSettingsDialog onClose={() => setIsSettingsDialogOpen(false)} />;
    }
  };

  return (
    <>
      {isMapVisualizationSettingsLoading ? (
        <div className={b('loadingSkeleton')} data-testid="loading-skeleton">
          <div className={Classes.SKELETON} />
        </div>
      ) : (
        <div className={b('titleWrapper')}>
          <div className={b('titleTextWrapper')}>
            <span className={b('title')} data-testid="map-grid-title">
              {mapGridTitleText}
            </span>
            {chosenCustomHierarchy && (
              <span className={b('subTitle')} data-testid="map-grid-sub-title">
                {chosenCustomHierarchyName}
              </span>
            )}
          </div>
          {canViewVisualizationSettings && (
            <IconButton
              type="button"
              icon={<SettingsAdjust />}
              testId="map-settings-button"
              onClick={() => setIsSettingsDialogOpen(true)}
            />
          )}
          {isSettingsDialogOpen && renderSettingsDialog()}
        </div>
      )}
    </>
  );
};

export default MapGridTitleV2;
