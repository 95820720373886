// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cVGDg {\n  margin-top: var(--m);\n}\n\n  .InjzU {\n    display: flex;\n    justify-content: space-between;\n    padding: var(--m) 0;\n  }\n\n  .H7xY6 {\n    margin-left: var(--m);\n  }\n\n  .saYcd {\n    margin-top: var(--m);\n  }\n\n  .xd6k4 {\n    padding: var(--m) 0;\n  }\n\n  .h1Tcz {\n    font-weight: 500;\n  }\n\n  .SQnO9 {\n    margin-top: var(--xs);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SegmentEditorDialog": "cVGDg",
	"SegmentEditorDialog_footer": "InjzU",
	"SegmentEditorDialog_rightActionButtons": "H7xY6",
	"SegmentEditorDialog_segmentNameField": "saYcd",
	"SegmentEditorDialog_applyCheckboxField": "xd6k4",
	"SegmentEditorDialog_applySegmentLabel": "h1Tcz",
	"SegmentEditorDialog_customHierarchyFilterMenuContainer": "SQnO9"
};
export default ___CSS_LOADER_EXPORT___;
