import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import {
  BreakdownClusterFn,
  ClusterProperties,
  clusterPropertyReducer,
  createBreakdownClusterFunction,
  createLassoFunction,
  IndividualPointProperties,
  LassoFn
} from 'app/utils/acccountGroveClusterHelpers';
import { CirclePaint, Expression, SymbolLayout, SymbolPaint } from 'mapbox-gl';
import { Layer, Source, useMap } from 'react-map-gl';

import { useApproximateMapView } from 'app/components/TerritoryMap/hooks/useApproximateMapView';

import { useMapWorkerPostMessage, useOnMapWorkerMessage } from 'app/contexts/mapWorkerContext';

import { CombinedRuleId, CustomerFeatureCollection } from 'app/models';

import { Grove } from 'utils/Grove';
import { getViewFromMap } from 'utils/helpers/territoryMapUtils';

import {
  LayerIds,
  MapStyleTokens,
  SourceIds,
  black,
  white,
  darkTerritoryColors,
  clusterBreakpointZoomLevels
} from './MapStyleTokens';

const isSelectedExpression: Expression = ['to-boolean', ['get', 'isSelected']];
const isGeoOverassignedExpression: Expression = ['to-boolean', ['get', 'isGeoOverassigned']];
const isAccountOverassignedExpression: Expression = ['to-boolean', ['get', 'accountOverassignmentCount']];
const isOverassignedExpression: Expression = ['any', isGeoOverassignedExpression, isAccountOverassignedExpression];

const baseColorExpression: Expression = ['coalesce', ['get', 'ruleColor'], MapStyleTokens.colors.unassignedPin];

const isMixedExpression: Expression = ['==', ['get', 'combinedRuleId'], CombinedRuleId.MIXED];

const isDarkColorExpression: Expression = ['in', ['get', 'ruleColor'], ['literal', darkTerritoryColors]];

const circleColor: Expression = [
  'case',
  isSelectedExpression,
  white,
  isMixedExpression,
  black,
  isOverassignedExpression,
  black,
  baseColorExpression
];

const circleStroke: Expression = [
  'case',
  isSelectedExpression,
  ['case', isOverassignedExpression, black, isMixedExpression, black, baseColorExpression],
  white
];

const clusterLayout: SymbolLayout = {
  'text-field': '{pointCountAbbreviated}',
  'text-font': [...MapStyleTokens.fonts.pins],
  'text-size': 18,
  'text-allow-overlap': true
};

const textPaint: SymbolPaint = {
  'text-opacity': 1,
  'text-color': ['case', isSelectedExpression, black, isMixedExpression, white, isDarkColorExpression, white, black]
};
const textFilter = ['has', 'pointCount'];

export type GroveClusterAccountSourceLayersProps = {
  accountFeatures: CustomerFeatureCollection;
};

export const GroveClusterAccountSourceLayers = forwardRef<
  GroveHelpersClusterRefHandle,
  GroveClusterAccountSourceLayersProps
>(function GroveClusterAccountSourceLayers({ accountFeatures }: GroveClusterAccountSourceLayersProps, groveHelpersRef) {
  const mapboxMapRef = useMap();
  const approximateView = useApproximateMapView();
  const lastInputFeaturesRef = useRef<CustomerFeatureCollection['features'] | null>(null);
  const [clusterFeatures, setClusterFeatures] = useState<Array<GeoJSON.Feature>>([]);

  const grove = useMemo(
    () =>
      new Grove<IndividualPointProperties, ClusterProperties>({
        zoomLevels: clusterBreakpointZoomLevels,
        reduce: clusterPropertyReducer
      }),
    []
  );

  useEffect(() => {
    if (!approximateView) return;
    if (accountFeatures.features && accountFeatures.features !== lastInputFeaturesRef.current) {
      lastInputFeaturesRef.current = accountFeatures.features;
      grove.load(accountFeatures.features);
    }
    setClusterFeatures(grove.getClustersByBounds(approximateView.zoom, approximateView.bounds));
  }, [grove, accountFeatures.features, approximateView]);

  useOnMapWorkerMessage(
    useCallback(
      (message) => {
        if (message.type !== 'set-accounts-is-selected') return;
        if (message.isSelected) {
          grove.selectPoints(message.ids);
        } else {
          grove.deselectPoints(message.ids);
        }
        const view = getViewFromMap(mapboxMapRef.current);
        if (!view) return;
        setClusterFeatures(grove.getClustersByBounds(view.zoom, view.bounds));
      },
      [grove]
    )
  );
  useRequestInitialSelection();

  useImperativeHandle(
    groveHelpersRef,
    (): GroveHelpersClusterRefHandle => ({
      lasso: createLassoFunction(grove, mapboxMapRef),
      breakdownCluster: createBreakdownClusterFunction(grove, mapboxMapRef)
    }),
    [grove]
  );

  const clusterFeatureCollection = useMemo(
    () => ({ type: 'FeatureCollection' as const, features: clusterFeatures }),
    [clusterFeatures]
  );

  const circlePaint = useMemo(
    () => createCirclePaint(accountFeatures.features.length),
    [accountFeatures.features.length]
  );

  return (
    <Source
      type="geojson"
      data={clusterFeatureCollection}
      id={SourceIds.account.cluster}
      key={SourceIds.account.cluster}
    >
      <Layer
        id={LayerIds.account.cluster.symbol}
        type="symbol"
        filter={textFilter}
        layout={clusterLayout}
        paint={textPaint}
      />
      <Layer
        id={LayerIds.account.cluster.interactiveCircle}
        beforeId={LayerIds.account.cluster.symbol}
        type="circle"
        paint={circlePaint}
      />
    </Source>
  );
});

const useRequestInitialSelection = () => {
  const postMessage = useMapWorkerPostMessage();
  useEffect(() => {
    postMessage({ type: 'get-initial-selected-accounts' });
  }, []);
};

const createCirclePaint = (featureCount: number): CirclePaint => ({
  'circle-color': circleColor,
  'circle-radius': [
    'case',
    ['all', ['<', featureCount, 200], ['has', 'pointCount']],
    20,
    ['has', 'pointCount'],
    [
      'interpolate',
      ['linear'],
      ['get', 'pointCount'],
      1,
      15,
      Math.max(2, featureCount * 0.1),
      40,
      Math.max(3, featureCount * 0.8),
      70,
      Math.max(4, featureCount),
      75
    ],
    7.5
  ],
  'circle-stroke-color': circleStroke,
  'circle-stroke-width': 2
});

export type GroveHelpersClusterRefHandle = { lasso: LassoFn; breakdownCluster: BreakdownClusterFn };
