import { useEffect, useState } from 'react';

import { MapboxMap } from 'react-map-gl';

export const useIsMapLayerPresent = (
  mapboxMap: Pick<MapboxMap, 'on' | 'off' | 'getLayer'> | null,
  layerId: string
): boolean => {
  const [isLayerVisible, setIsLayerVisible] = useState(() => !!mapboxMap?.getLayer(layerId));

  useEffect(() => {
    if (!mapboxMap) return noCleanup;
    const handleStyleData = (): void => {
      const isLayerPresent = !!mapboxMap.getLayer(layerId);
      setIsLayerVisible(isLayerPresent);
    };
    handleStyleData();
    mapboxMap.on('styledata', handleStyleData);
    return () => {
      mapboxMap.off('styledata', handleStyleData);
    };
  }, [mapboxMap, layerId]);

  return isLayerVisible;
};

export const useIsMapSourcePresent = (
  mapboxMap: Pick<MapboxMap, 'on' | 'off' | 'getSource'> | null,
  sourceId: string
): boolean => {
  const [isSourceVisible, setIsSourceVisible] = useState(() => !!mapboxMap?.getSource(sourceId));

  useEffect(() => {
    if (!mapboxMap) return noCleanup;
    const handleStyleData = (): void => {
      const isSourcePresent = !!mapboxMap.getSource(sourceId);
      setIsSourceVisible(isSourcePresent);
    };
    handleStyleData();
    mapboxMap.on('styledata', handleStyleData);
    return () => {
      mapboxMap.off('styledata', handleStyleData);
    };
  }, [mapboxMap, sourceId]);

  return isSourceVisible;
};

const noCleanup = () => {
  // This is a no-op to allow for early returns in useEffect
};
