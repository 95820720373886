export enum SplitFeatures {
  // permanent Splits
  MAINTENANCE_MODE = 'MAINTENANCE_MODE',
  SYMON_EMBED = 'SYMON_EMBED',
  SYMON_EMBED_CONF = 'SYMON_EMBED_CONF',
  CONTRIBUTOR_VIEW_MAPS_PINS = 'CONTRIBUTOR_VIEW_MAPS_PINS',

  // feature Splits
  TQM = 'TQM',
  FILE_ERROR_TAB_ENABLED = 'FILE_ERROR_TAB_ENABLED',
  COPILOT = 'COPILOT',
  COPY_PC_PROGRESS = 'COPY_PC_PROGRESS',
  CUSTOM_HIERARCHY_ATTRIBUTES_V2 = 'CUSTOM_HIERARCHY_ATTRIBUTES_V2',
  IMPORT_HIERARCHIES_SYMON = 'IMPORT_HIERARCHIES_SYMON',
  COLUMN_SUBSET_MAPPING = 'COLUMN_SUBSET_MAPPING',
  IS_SHARED_COMPUTED_COLUMN_LIB_ENABLED = 'IS_SHARED_COMPUTED_COLUMN_LIB_ENABLED',
  TERRITORY_OPTIMIZATION = 'TERRITORY_OPTIMIZATION',
  BULK_DELETION_TERRITORIES = 'BULK_DELETION_TERRITORIES',
  BREAKDOWN_OF_QUOTA_BY_HIERARCHIES = 'BREAKDOWN_OF_QUOTA_BY_HIERARCHIES',
  HIERARCHY_BASED_TOP_DOWN_TARGET_SETTING = 'HIERARCHY_BASED_TOP_DOWN_TARGET_SETTING',
  QUOTA_TARGET_SETTING = 'QUOTA_TARGET_SETTING',
  HIDECALCCOLUMNTOTALS = 'HIDECALCCOLUMNTOTALS',
  CALC_COLUMN_UPDATE = 'CALC_COLUMN_UPDATE',
  CUSTOMER_PINS_PAGING = 'CUSTOMER_PINS_PAGING',
  MAP_UNDO = 'MAP_UNDO',
  PERIODICITY_CALC_COLUMN = 'PERIODICITY_CALC_COLUMN',
  NEW_TERRITORY_QUOTA_FILE_MAPPING_FIELDS = 'NEW_TERRITORY_QUOTA_FILE_MAPPING_FIELDS',
  HIDE_CALC_PERCENT_TOTALS = 'HIDE_CALC_PERCENT_TOTALS',
  MAP_SINGLE_CUSTOM_HIERARCHY = 'MAP_SINGLE_CUSTOM_HIERARCHY',
  COMMENTING_MANAGE_WORKFLOW = 'COMMENTING_MANAGE_WORKFLOW',
  TDA_ASYNC = 'TDA_ASYNC',
  COLUMN_HIGHLIGHTING = 'COLUMN_HIGHLIGHTING',
  MAP_CENTERING = 'MAP_CENTERING',
  MATCHING_ENGINE = 'MATCHING_ENGINE',
  AUDIT_TRAIL_EXPORT_ASYNC = 'AUDIT_TRAIL_EXPORT_ASYNC',
  SINGLE_ACCOUNT_TERRITORY = 'SINGLE_ACCOUNT_TERRITORY',
  AMWQ_ACCOUNT_QUOTA_IN_GRID = 'AMWQ_ACCOUNT_QUOTA_IN_GRID',
  AMWQ_TWO_HIERARCHIES = 'AMWQ_TWO_HIERARCHIES',
  AMWQ_TWO_HIERARCHIES_SORT_FILTER = 'AMWQ_TWO_HIERARCHIES_SORT_FILTER',
  HBTS_WITH_100_HIERARCHY_LIMIT = 'HBTS_WITH_100_HIERARCHY_LIMIT',
  SKIP_MAP_PROXY = 'SKIP_MAP_PROXY',
  PRESERVE_SEASONALITY_TDA = 'PRESERVE_SEASONALITY_TDA',
  REMOVE_BOOTSTRAP = 'REMOVE_BOOTSTRAP',
  AMWQ_ACCOUNT_QUOTA_IN_TERRITORY_QUOTA_GRID = 'AMWQ_ACCOUNT_QUOTA_IN_TERRITORY_QUOTA_GRID',
  AMWQ_MAX_SELLERS = 'AMWQ_MAX_SELLERS',
  SELLER_ASSIGNMENT_FILE_UPLOAD_MANAGE = 'SELLER_ASSIGNMENT_FILE_UPLOAD_MANAGE',
  TERRITORY_LOCKING = 'TERRITORY_LOCKING',
  ALLOW_CUSTOM_SYMON_DOMAIN = 'ALLOW_CUSTOM_SYMON_DOMAIN',
  MANAGE_QBT_SORT_FILTER = 'MANAGE_QBT_SORT_FILTER',
  ACCOUNT_FIRST_MAP = 'ACCOUNT_FIRST_MAP',
  ACCOUNT_HIERARCHY_PINS = 'ACCOUNT_HIERARCHY_PINS',
  HIERARCHY_DATE_TYPE = 'HIERARCHY_DATE_TYPE',
  VDS_TOAST = 'VDS_TOAST',
  SELLER_ASSIGNMENT_TWO_DECIMAL_PLACES = 'SELLER_ASSIGNMENT_TWO_DECIMAL_PLACES',
  BASE_RULE_FILTERS = 'BASE_RULE_FILTERS',
  MATCH_ON_SELECTION_TOGGLE = 'MATCH_ON_SELECTION_TOGGLE',
  HIERARCHY_EFFECTIVE_DATING = 'HIERARCHY_EFFECTIVE_DATING',
  DUPLICATE_ACCOUNT_RULE_GRID_OVERRIDE = 'DUPLICATE_ACCOUNT_RULE_GRID_OVERRIDE',
  DUPLICATION_FID_IN_RULE_EDITOR_EXCEPTION_FILE = 'DUPLICATION_FID_IN_RULE_EDITOR_EXCEPTION_FILE',
  DELETE_ACTIVITY_FILE = 'DELETE_ACTIVITY_FILE',
  MAP_HIGHWAY_HIGHLIGHTING = 'MAP_HIGHWAY_HIGHLIGHTING',
  NEW_ACCOUNT_ROUTING_MANAGE = 'NEW_ACCOUNT_ROUTING_MANAGE',
  ACCOUNT_MAP_SOURCE_FILTER = 'ACCOUNT_MAP_SOURCE_FILTER',
  MAP_STYLE_ENHANCEMENTS = 'MAP_STYLE_ENHANCEMENTS',
  QUOTA_GRID_TOTALS_SLOWNESS = 'QUOTA_GRID_TOTALS_SLOWNESS',
  DISALLOW_MEASURE_NAME_WITH_UNDERSCORE = 'DISALLOW_MEASURE_NAME_WITH_UNDERSCORE',
  TERRITORY_LOCKING_ENHANCEMENTS = 'TERRITORY_LOCKING_ENHANCEMENTS',
  COPY_PC_ECS = 'COPY_PC_ECS',
  IN_HOUSE_CLUSTERING = 'IN_HOUSE_CLUSTERING',
  MAP_SEGMENTATION = 'MAP_SEGMENTATION',
  ACCOUNT_MAP_WARNING = 'ACCOUNT_MAP_WARNING',
  ACCOUNT_QUOTA_GRID_FILTER = 'ACCOUNT_QUOTA_GRID_FILTER'
}
