import React from 'react';

import { Filter, ChevronDown } from '@carbon/icons-react';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import AccountQuotaTGTFilter from 'app/components/AdvancedGrid/Sheets/Account/AccountQuotaTGTFilter';
import AccountRuleFilter from 'app/components/AdvancedGrid/Sheets/AccountRule/AccountRuleFilter';

import { SplitFeatures } from 'app/global/features';

import { GetAccountRuleGrid_getAccountRuleGrid_accountRuleHeaders } from 'app/graphql/generated/graphqlApolloTypes';

import useTreatment from 'app/hooks/useTreatment';

import { FilterChangeInput, FilterInput, NamedTgt } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './AccountQuotaHeader.module.pcss';

const b = block(style);

export interface AccountQuotaHeaderProps {
  territoryGroupTypes: NamedTgt[];
  currentTerritoryGroupTypeId: number;
  onTerritoryGroupTypeChange: (territoryGroupType: number) => void;
  onFilterChange: (updatedFilter: FilterChangeInput) => void;
  onFilterApply: () => void;
  dynamicColumns: GetAccountRuleGrid_getAccountRuleGrid_accountRuleHeaders[];
  accountFilters: FilterInput;
  activeFiltersCount: number;
  onClearAll: () => void;
}

const AccountQuotaHeader: React.FC<AccountQuotaHeaderProps> = ({
  territoryGroupTypes,
  onTerritoryGroupTypeChange,
  currentTerritoryGroupTypeId,
  onFilterChange,
  onFilterApply,
  dynamicColumns,
  accountFilters,
  activeFiltersCount,
  onClearAll
}) => {
  const [isAccountQuotaFilterEnabled] = useTreatment(SplitFeatures.ACCOUNT_QUOTA_GRID_FILTER);
  const filterButtonText = (
    <div>
      {formatMessage('FILTERS')}
      {!!activeFiltersCount && <span className={b('filterCount')}>{activeFiltersCount}</span>}
    </div>
  );

  return (
    <header className={b()}>
      <div className={b('container')}>
        <div className={b('tgtWrapper')}>
          <AccountQuotaTGTFilter
            territoryGroupTypes={territoryGroupTypes}
            onTerritoryGroupTypeChange={onTerritoryGroupTypeChange}
            currentTerritoryGroupType={currentTerritoryGroupTypeId}
          />
          {!isAccountQuotaFilterEnabled && (
            <>
              <Popover
                content={
                  <AccountRuleFilter
                    dynamicColumns={dynamicColumns}
                    onFilterChange={onFilterChange}
                    accountFilters={accountFilters}
                    onFilterApply={onFilterApply}
                  />
                }
                placement={'bottom-start'}
                onClosed={onFilterApply}
              >
                <TextButton
                  text={filterButtonText}
                  icon={<Filter size={20} />}
                  type="button"
                  testId="filter-button"
                  minimal
                  large={false}
                  className={b('filterButton')}
                  rightIcon={<ChevronDown size={20} />}
                />
              </Popover>
              {!!activeFiltersCount && (
                <TextButton
                  text={formatMessage('CLEAR_ALL')}
                  type="button"
                  testId="clear-all-button"
                  minimal
                  large={false}
                  className={b('filterButton')}
                  onClick={onClearAll}
                />
              )}
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default AccountQuotaHeader;
