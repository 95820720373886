import { ColDef } from '@ag-grid-community/core';
import dayjs from 'dayjs';

import { getPlanDurationMonths } from 'app/components/AdvancedGrid/Sheets/Territory/TerritoryQuotaGrid/TerritoryQuotaGridUtils';

import {
  GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution,
  AccountQuotaAdjustmentType,
  AccountQuotaRedirectDirection
} from 'app/graphql/generated/graphqlApolloTypes';

import { MeasureType, MonthMap, SelectedPlanningCycle } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

export const formatTerritoryQuotaDrillInData = (
  data: GetAccountQuotaRedirectDistribution_getTerritoryQuota_accountRedirectQuotaDistribution[],
  monthlyBreakdownColumns: string[]
): Array<ColDef> => {
  if (!data) return [];
  const gridData = [];

  data.forEach(
    ({
      quotaRedirect,
      alignmentEffectiveDate,
      measures,
      adjustmentType,
      accountKey,
      accountName,
      customHierarchyKey,
      customHierarchyName
    }) => {
      const accountMoveFrom =
        quotaRedirect && quotaRedirect.moveDirection === AccountQuotaRedirectDirection.from
          ? `${quotaRedirect.territory.territoryName} (${quotaRedirect.territory.territoryId})`
          : null;
      const accountMoveTo =
        quotaRedirect && quotaRedirect.moveDirection === AccountQuotaRedirectDirection.to
          ? `${quotaRedirect.territory.territoryName} (${quotaRedirect.territory.territoryId})`
          : null;

      const sortedAlignmentEffectiveDate = alignmentEffectiveDate.sort((date1, date2) =>
        date1.periodStartDate.localeCompare(date2.periodStartDate)
      );
      const formattedAlignmentEffectiveDate = sortedAlignmentEffectiveDate.map((period) => ({
        periodStartDate: dayjs(period.periodStartDate).format('MM/DD/YYYY'),
        periodEndDate: dayjs(period.periodEndDate).format('MM/DD/YYYY')
      }));

      // In case: adjustmentType is Original, quota column is reference to Account Quota
      // In case: adjustmentType is Account Move, quota column is reference to Territory Quota adjustment
      const quotaMeasure = measures.find((measure) => {
        if (adjustmentType === AccountQuotaAdjustmentType.Original) {
          return measure.measureName === MeasureType.ACCOUNT_QUOTA;
        } else if (adjustmentType === AccountQuotaAdjustmentType.AccountMove) {
          return measure.measureName === MeasureType.TERRITORY_QUOTA_ADJUSTMENT;
        }
        return null;
      });
      const quotaMeasureValue = quotaMeasure?.measureValue;
      const monthlyBreakdownValues = {};

      monthlyBreakdownColumns.forEach((month) => {
        if (!quotaMeasure) {
          return null;
        }

        const monthlyBreakdownMeasure = quotaMeasure.measureValueBreakdown.find((value) => {
          const periodStartDate = value.periodStartDate;
          const startMonth = MonthMap[+periodStartDate.split('-')[1]];
          if (startMonth === month) {
            return value.measureValue;
          }
          return null;
        });
        const monthlyMeasureBreakdownValue = monthlyBreakdownMeasure?.measureValue;

        if (monthlyMeasureBreakdownValue) {
          monthlyBreakdownValues[month] = monthlyMeasureBreakdownValue;
        }
      });
      const formattedDataItem = {
        accountKey,
        accountName,
        adjustmentType:
          adjustmentType === AccountQuotaAdjustmentType.Original
            ? formatMessage('ORIGINAL')
            : formatMessage('ACCOUNT_MOVE'),
        accountMovedFrom: accountMoveFrom,
        accountMovedTo: accountMoveTo,
        alignmentEffectiveDate: formattedAlignmentEffectiveDate,
        quota: quotaMeasureValue,
        ...(customHierarchyKey && customHierarchyName
          ? {
              customHierarchyName,
              customHierarchyKey
            }
          : {}),
        ...monthlyBreakdownValues
      };
      gridData.push(formattedDataItem);
    }
  );

  return gridData;
};

export const generateMonthlyBreakdownColumns = (selectedPlanningCycle: SelectedPlanningCycle): string[] => {
  const { planningCycleStartDate, planningCycleDuration } = selectedPlanningCycle;
  const durationMonths = getPlanDurationMonths(planningCycleStartDate, planningCycleDuration);
  const breakdowns = Object.keys(durationMonths).map((key) => MonthMap[durationMonths[key]]);
  return breakdowns;
};
