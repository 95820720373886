import React, { useEffect, useRef, useState } from 'react';

import {
  GridReadyEvent,
  ModelUpdatedEvent,
  SelectionChangedEvent,
  ServerSideStoreType,
  SortChangedEvent
} from '@ag-grid-community/core';
// eslint-disable-next-line no-restricted-imports
import { useLazyQuery } from '@apollo/client';
import { MisuseOutline } from '@carbon/icons-react';

import IconButton from 'components/Buttons/IconButton/IconButton';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import { getPaginationCheck } from 'app/components/AdvancedGrid/GridHelper';
import buildBalancingPanelColumnDef from 'app/components/AdvancedGrid/GridHelpers/TerritoryBalancing/buildBalancingPanelColumnDef';

import { BALANCING_CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { useRebalancing } from 'app/contexts/rebalancingProvider';

import { BLOCK_SIZE, PROGRESS_MIN_LENGTH } from 'app/global/variables';

import {
  GetAggregatedActivitiesForTerritoryWithMeasureValue,
  GetAggregatedActivitiesForTerritoryWithMeasureValueVariables,
  GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList,
  SortDirection,
  SortModel
} from 'app/graphql/generated/graphqlApolloTypes';
import { handleError } from 'app/graphql/handleError';
import { GET_AGGREGATED_ACTIVITIES_FOR_TERRITORY_WITH_MEASURE_VALUE } from 'app/graphql/queries/getAggregatedActivitiesForTerritoryWithMeasureValue';

import { BalancingPanelColumnNames, BalancingPanelRow, GridHeaders, SourcePanel } from 'app/models/index';

import block from 'utils/bem-css-modules';
import { formatNumberByMeasureType } from 'utils/helpers/currencyHelpers';
import { formatMessage } from 'utils/messages/utils';

import style from './BalancingPanel.module.pcss';

const b = block(style);

interface BalancingPanelProps {
  onClose: () => void;
  isLeftPanel: boolean;
  measureType: string;
}

export enum BalancingHeaderNames {
  CUSTOMER_ACCOUNT_ID = 'Customer Account Id',
  GEOGRAPHY_ID = 'Geography Id'
}

const BalancingPanel: React.FC<BalancingPanelProps> = ({ onClose, isLeftPanel, measureType }: BalancingPanelProps) => {
  const { battleCardLookupMap, selectedBattleCardId, selectedQuotaComponentId } = useBattleCard();
  const { defaultReportingCurrency } = useLocalization();
  const {
    selectedRebalancingRows,
    selectedRebalancingMetric,
    selectedRebalancingHierarchy,
    isMoved,
    metrics,
    sourcePanel,
    setSourcePanel,
    selectedData,
    setSelectedData,
    ruleFilterEnabled
  } = useRebalancing();

  const rebalancingTerritoryId = isLeftPanel
    ? selectedRebalancingRows[0]?.territoryId
    : selectedRebalancingRows[1]?.territoryId;
  const rebalancingRuleId = isLeftPanel ? selectedRebalancingRows[0]?.ruleId : selectedRebalancingRows[1]?.ruleId;

  const [columnDefs, setColumnDefs] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [updatedMetricTotal, setUpdatedMetricTotal] = useState(null);

  const [sortModel, setSortModel] = useState<SortModel>({});

  const containerRef = useRef(null);

  const battleCardLocalCurrencyCode = battleCardLookupMap?.[selectedBattleCardId]?.localCurrencyCode;
  const currency = battleCardLocalCurrencyCode || defaultReportingCurrency;

  const isSourcePanel =
    (isLeftPanel && sourcePanel === SourcePanel.LEFT) || (!isLeftPanel && sourcePanel === SourcePanel.RIGHT);
  const isDisabled =
    (isLeftPanel && sourcePanel === SourcePanel.RIGHT) || (!isLeftPanel && sourcePanel === SourcePanel.LEFT) || isMoved;
  const mean = metrics?.measureValueMean || 0;

  const [
    getAggregatedActivities,
    {
      data: aggregatedActivities,
      loading: aggregatedActivitiesForTerritoryLoading,
      error: getAggregatedActivitiesForTerritoryError,
      fetchMore
    }
  ] = useLazyQuery<
    GetAggregatedActivitiesForTerritoryWithMeasureValue,
    GetAggregatedActivitiesForTerritoryWithMeasureValueVariables
  >(GET_AGGREGATED_ACTIVITIES_FOR_TERRITORY_WITH_MEASURE_VALUE, {
    fetchPolicy: 'no-cache', // ensure the fetch policy is no-cache, for more context as to why see the description of https://github.com/varicent-tq/tq-ui/pull/1550
    variables: {
      battlecardId: +selectedBattleCardId,
      territoryId: rebalancingTerritoryId,
      quotaComponentId: selectedQuotaComponentId,
      startRow: 1,
      endRow: BLOCK_SIZE,
      hierarchies: [selectedRebalancingHierarchy?.value],
      measures: [selectedRebalancingMetric?.key],
      ruleId: rebalancingRuleId,
      sorting: sortModel,
      ruleFilterEnabled
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    }
  });

  const aggregatedActivitiesForTerritory = aggregatedActivities?.getAggregatedActivitiesForTerritoryWithMeasureValue;
  const aggregatedActivitiesList = aggregatedActivitiesForTerritory?.territoryAggregatedActivitiesList || [];
  const totalCount = aggregatedActivitiesForTerritory?.territoryAggregatedActivitiesCount || 0;
  const summedMetrics = aggregatedActivitiesForTerritory?.territoryAggregatedActivitiesTotals?.[0];
  const metricTotal = summedMetrics?.measureValue || 0;

  useEffect(() => {
    getAggregatedActivities();
  }, [selectedRebalancingHierarchy, isMoved]);

  useEffect(() => {
    setColumnDefs(buildBalancingPanelColumnDef(measureType, currency, selectedRebalancingHierarchy?.key));
  }, [battleCardLocalCurrencyCode, defaultReportingCurrency, selectedRebalancingHierarchy, measureType]);

  useEffect(() => {
    gridApi?.setColumnDefs(columnDefs);
  }, [gridApi]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  const handleModelUpdate = (event: ModelUpdatedEvent) => {
    setCountAndTotal(event, selectedData);
  };

  const handleSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedRowsData = selectedNodes.map((node) => node.data);

    if (!isMoved) {
      setSelectedData(selectedRowsData);
      setCountAndTotal(event, selectedRowsData);
    }
    if (!selectedRowsData || selectedRowsData?.length === 0) {
      setSourcePanel(null);
    } else if (isLeftPanel) {
      setSourcePanel(SourcePanel.LEFT);
    } else {
      setSourcePanel(SourcePanel.RIGHT);
    }
  };

  const getBalancingMetricChange = () => {
    let balancingMetricChange = 0;
    const data = selectedData || [];
    for (const row of data) {
      balancingMetricChange += row?.balancingMetric;
    }
    return balancingMetricChange;
  };

  const handleSortChanged = (gridEvent: SortChangedEvent): void => {
    const model: SortModel = {};

    const { columnApi } = gridEvent;

    const columns = columnApi.getAllColumns();

    const gridColumns = columns
      ?.filter(
        (col) =>
          col.getSort() !== undefined || col.getColDef()?.headerName !== BalancingPanelColumnNames.BALANCING_METRIC
      )
      .map((col) => {
        switch (col.getColDef()?.headerName) {
          case GridHeaders.CUSTOMER_ACCOUNTS:
            return {
              colId: BalancingHeaderNames.CUSTOMER_ACCOUNT_ID,
              sort: col.getSort() as SortDirection
            };

          case GridHeaders.GEOGRAPHIES:
            return {
              colId: BalancingHeaderNames.GEOGRAPHY_ID,
              sort: col.getSort() as SortDirection
            };

          default:
            return {
              colId: col.getColId(),
              sort: col.getSort() as SortDirection
            };
        }
      });

    model.sortModel = gridColumns;

    setSortModel(model);
  };

  const setCountAndTotal = (event, selectedRowsData?) => {
    let sumOfSelected = 0;
    let total = 0;

    let countMessage = '';
    let totalMessage = '';

    const balancingMetricChange = getBalancingMetricChange();
    if (isSourcePanel) {
      total = +metricTotal - balancingMetricChange;
    } else {
      total = +metricTotal + balancingMetricChange;
    }

    // if rows are selected prior to moving, calculate count and sum of selected
    const selectedCount = selectedRowsData?.length;
    selectedRowsData?.forEach((row) => {
      sumOfSelected += row?.balancingMetric;
    });

    if (selectedCount && !isMoved) {
      totalMessage = formatMessage('TOTAL_WITH_FRACTION', {
        numerator: formatNumberByMeasureType(sumOfSelected, measureType, currency),
        denominator: formatNumberByMeasureType(total, measureType, currency)
      });
      countMessage = formatMessage('COUNT_WITH_FRACTION', { numerator: selectedCount, denominator: totalCount });
    } else {
      totalMessage = formatMessage('TOTAL_WITH_VALUE', {
        value: formatNumberByMeasureType(total, measureType, currency)
      });

      if (isMoved && isSourcePanel) {
        // subtract selected rows from count
        countMessage = formatMessage('COUNT_WITH_NUMBER', { number: totalCount - selectedCount });
      } else if (isMoved && !isSourcePanel) {
        // add selected rows to count
        countMessage = formatMessage('COUNT_WITH_NUMBER', { number: totalCount + selectedCount });
      } else {
        // show count without adjustments if nothing is selected or moved
        countMessage = formatMessage('COUNT_WITH_NUMBER', { number: totalCount });
      }
    }

    const pinnedData = {
      balancingHierarchyName: countMessage,
      balancingMetric: totalMessage
    };

    setUpdatedMetricTotal(total);
    event.api.setPinnedBottomRowData([pinnedData]);
  };

  // takes in an array of object, extract useful info from the object and ready to be used in the grid
  const formatRows = (
    territoryAggregatedActivitiesList: GetAggregatedActivitiesForTerritoryWithMeasureValue_getAggregatedActivitiesForTerritoryWithMeasureValue_territoryAggregatedActivitiesList[],
    balancingMetricChange = 0
  ): BalancingPanelRow[] => {
    return territoryAggregatedActivitiesList.map((item) => {
      let total;
      if (isSourcePanel) {
        total = metricTotal - balancingMetricChange;
      } else {
        total = metricTotal + balancingMetricChange;
      }
      const statusBarProgress = total ? item?.measures?.[0]?.measureValue / total : 0;

      return {
        balancingHierarchyName: item?.hierarchies?.[0]?.val,
        id: item?.hierarchies?.[0]?.id || '',
        balancingMetric: item?.measures?.[0]?.measureValue || 0,
        progress:
          statusBarProgress !== 0 && statusBarProgress < PROGRESS_MIN_LENGTH
            ? PROGRESS_MIN_LENGTH
            : +statusBarProgress.toFixed(2),
        // when there is a mismatch in geo hierarchies and activities, id will be null in the response, can not rebalancing
        isValidForRebalance: !!item?.rebalancingPermissions?.isValidForRebalance && !!item?.hierarchies?.[0]?.id
      };
    });
  };

  const gridOptions = {
    headerHeight: BALANCING_CELL_HEIGHT,
    rowHeight: BALANCING_CELL_HEIGHT,
    onModelUpdated: handleModelUpdate,
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial' as ServerSideStoreType,
    cacheBlockSize: BLOCK_SIZE,
    onSelectionChanged: handleSelectionChanged,
    suppressRowClickSelection: true,
    getRowClass: (params) => {
      if (params?.node?.data?.isAddition) {
        return 'blueBackground';
      } else if (params?.node?.data?.isRemoved) {
        return 'redBackground';
      }
      const canSelect = params?.data?.isValidForRebalance;
      if (!canSelect) {
        return 'disabledSelection';
      }
      return null;
    },
    serverSideDatasource: {
      getRows: async (params) => {
        const updateSourceRows = (rowData) => {
          return rowData?.map((row) => {
            selectedData?.forEach((data) => {
              if (data?.id === row?.id) {
                row.isRemoved = true;
              }
            });
            return row;
          });
        };
        const balancingMetricChange = getBalancingMetricChange();
        let skippedRowsList;
        if (params?.request?.endRow === BLOCK_SIZE) {
          // no need to query for the first block as we already fetched it in the initial load
          let rowData = formatRows(aggregatedActivitiesList, balancingMetricChange);
          if (!isMoved) {
            params?.success({ rowData, rowCount: totalCount });
          } else {
            if (isSourcePanel) {
              rowData = updateSourceRows(rowData);
              params?.success({ rowData, rowCount: totalCount });
            } else {
              selectedData?.forEach((item) => {
                item.isAddition = true;
                item.progress =
                  metricTotal + balancingMetricChange
                    ? +(item?.balancingMetric / (metricTotal + balancingMetricChange)).toFixed(2)
                    : 0;
              });
              rowData = [...selectedData, ...rowData];
              params?.success({ rowData, rowCount: totalCount + selectedData.length });
            }
          }
          // ag-grid starts row count from 0 and the backend pagination starts from 1
        } else if (getPaginationCheck(params.request.startRow, totalCount)) {
          const fetchMoreActivities = await fetchMore<
            GetAggregatedActivitiesForTerritoryWithMeasureValue,
            GetAggregatedActivitiesForTerritoryWithMeasureValueVariables
          >({
            variables: {
              startRow: params.request.startRow + 1,
              endRow: params.request.endRow,
              battlecardId: +selectedBattleCardId,
              territoryId: rebalancingTerritoryId,
              quotaComponentId: selectedQuotaComponentId,
              hierarchies: [selectedRebalancingHierarchy?.value],
              measures: [selectedRebalancingMetric?.key],
              ruleId: rebalancingRuleId
            }
          });
          const aggregatedList =
            fetchMoreActivities?.data?.getAggregatedActivitiesForTerritoryWithMeasureValue
              ?.territoryAggregatedActivitiesList || [];
          let rowData = formatRows(aggregatedList, balancingMetricChange);
          if (isSourcePanel) {
            rowData = updateSourceRows(rowData);
            params?.success({ rowData, rowCount: totalCount });
          } else if (isMoved && rowData?.length < 10) {
            // Need to fetch and reinsert the removed data from the first cache block in the destination panel after moving
            const fetchSkippedActivities = await fetchMore<
              GetAggregatedActivitiesForTerritoryWithMeasureValue,
              GetAggregatedActivitiesForTerritoryWithMeasureValueVariables
            >({
              variables: {
                // Reinserting rows that were removed from the destination panel in the initial cache block because of the inserted rows from the source panel
                startRow: 11 - selectedData.length,
                endRow: 10,
                battlecardId: +selectedBattleCardId,
                territoryId: rebalancingTerritoryId,
                quotaComponentId: selectedQuotaComponentId,
                hierarchies: [selectedRebalancingHierarchy?.value],
                measures: [selectedRebalancingMetric?.key],
                ruleId: rebalancingRuleId
              }
            });
            skippedRowsList =
              fetchSkippedActivities?.data?.getAggregatedActivitiesForTerritoryWithMeasureValue
                ?.territoryAggregatedActivitiesList || [];
            const skippedRowData = formatRows(skippedRowsList, balancingMetricChange);
            rowData = [...rowData, ...skippedRowData];
            // creating more space for added rows in destination panel
            params?.success({ rowData, rowCount: totalCount + selectedData.length });
          } else {
            params?.success({ rowData, rowCount: totalCount });
          }
        } else if (
          isMoved &&
          !isSourcePanel &&
          getPaginationCheck(params.request.startRow, totalCount + selectedData.length)
        ) {
          // Need to fetch and reinsert the remaining removed data from the first cache block in the destination panel after moving
          const startRow =
            totalCount > 10 ? 11 - selectedData.length + (10 - (totalCount % 10)) : 11 - selectedData.length;
          const fetchSkippedActivities = await fetchMore<
            GetAggregatedActivitiesForTerritoryWithMeasureValue,
            GetAggregatedActivitiesForTerritoryWithMeasureValueVariables
          >({
            variables: {
              // Reinserting remaining rows based on how much the previous cache block allowed (limit 10)
              startRow,
              endRow: 10,
              battlecardId: +selectedBattleCardId,
              territoryId: rebalancingTerritoryId,
              quotaComponentId: selectedQuotaComponentId,
              hierarchies: [selectedRebalancingHierarchy?.value],
              measures: [selectedRebalancingMetric?.key],
              ruleId: rebalancingRuleId
            }
          });
          skippedRowsList =
            fetchSkippedActivities?.data?.getAggregatedActivitiesForTerritoryWithMeasureValue
              ?.territoryAggregatedActivitiesList || [];
          const skippedRowData = formatRows(skippedRowsList, balancingMetricChange);
          params?.success({ rowData: skippedRowData, rowCount: totalCount + selectedData.length });
        }
      }
    }
  };

  let headerTotal = '';
  if (updatedMetricTotal !== null) {
    const differenceFromMean = updatedMetricTotal - mean;
    const formattedDifferenceFromMean = formatNumberByMeasureType(Math.abs(differenceFromMean), measureType, currency);

    if (differenceFromMean < 0) {
      headerTotal = formatMessage('UNDER_BY', { value: formattedDifferenceFromMean });
    } else if (differenceFromMean > 0) {
      headerTotal = formatMessage('OVER_BY', { value: formattedDifferenceFromMean });
    } else {
      headerTotal = formatMessage('BALANCED');
    }
  }

  return (
    <div
      className={`${b('container')} ${isDisabled ? b('disabled') : b('')}`}
      data-testid="balancing-panel"
      ref={containerRef}
    >
      <div className={b('header')}>
        <div className={b('headerLeft')}>{rebalancingTerritoryId}</div>
        <div className={b('headerRight')}>
          <div className={b('headerTotal')} data-testid="balancing-panel-header-total">
            {headerTotal}
          </div>
          <div className={b('closeButtonContainer')} onClick={onClose}>
            <IconButton
              type={'button'}
              icon={<MisuseOutline size={20} />}
              testId={'close-button'}
              title={formatMessage('CLOSE')}
            />
          </div>
        </div>
      </div>
      {aggregatedActivitiesForTerritoryLoading ||
      aggregatedActivitiesList?.length > 0 ||
      (selectedData.length > 0 && isMoved) ? (
        <AdvancedGrid
          gridOptions={gridOptions}
          data-testid="balancing-panel-grid"
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowSelection="multiple"
          gridHeight={containerRef?.current?.offsetHeight}
          gridWidth={containerRef?.current?.offsetWidth}
          showGridLoading={aggregatedActivitiesForTerritoryLoading}
          onSortChanged={handleSortChanged}
        />
      ) : (
        <div data-testid="balancing-panel-error-message" className={b('fallbackMessage')}>
          {getAggregatedActivitiesForTerritoryError?.message}
        </div>
      )}
    </div>
  );
};

export default BalancingPanel;
